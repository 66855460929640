import { buildQueryString } from '@/utils/url-action'
import { buildCalendarParams } from '@/utils/layoutData'
import { APP_VERSION_HEADER, COOKIE_MJ_SESSION, COOKIE_PROFILE_FOR_SEARCH_KEYS } from '@/utils/constants/commons'
import SearchParams from '@/utils/search/SearchParams'
import UserAgent from '@/utils/userAgent'
import ABTestClientIDService from '@/utils/ABTestClientIDService'
import { useRequestHeaders } from '#app'
import { parseCookies } from 'h3'
import { useMainStore } from '@/store'
import { handleError, emptyPromise } from './shared'
import { apiGet } from './api-core'
import { useNuxtApp, callWithNuxt } from 'nuxt/app'

// TODO: 速度が遅かったら、こちらのリクエストは各ページのasyncDataと一緒に取得した方が早くなるかもしれません
// ページ初期化用データを取得する
const fetchCommonData = async (app, mainStore, route, isSmartPhone) => {
  const calenderApiPath = `calendar/${buildQueryString(buildCalendarParams(mainStore, route))}`
  const sideContentPath = `side_contents/${new SearchParams(mainStore, route).searchSideContentQueryString}`
  try {
    const [userInfo, calendarData, sideContents, bannerHeaderRes] = await Promise.all([
      mainStore.$state.auth_info ? callWithNuxt(app, async () => await apiGet('user_info/')) : emptyPromise,
      !isSmartPhone ? callWithNuxt(app, async () => await apiGet(`${calenderApiPath}`)) : emptyPromise,
      callWithNuxt(app, async () => await apiGet(`${sideContentPath}`)),
      callWithNuxt(
        app,
        async () =>
          await apiGet('banner_header/').then(res => {
            const appVersion = res.headers && res.headers[APP_VERSION_HEADER]
            return { app_version: appVersion, data: res.data }
          })
      )
    ])

    const bannerHeader = bannerHeaderRes.data
    // init MJ app version
    // store [x-app-version] header to manage app version
    // If app version was changed, force reload page
    const appVersion = bannerHeaderRes.app_version
    if (appVersion) mainStore.SET_STATE({ key: 'app_version', value: appVersion })

    if (userInfo) mainStore.SET_STATE({ key: 'my_profile', value: userInfo.data.user })

    if (calendarData && calendarData.data && calendarData.data.calendar) {
      mainStore.LAYOUT_DATA({
        type: 'calendar',
        data: {
          conditions: buildCalendarParams(mainStore, route),
          data: calendarData.data.calendar
        }
      })
    }
    if (sideContents && sideContents.data) {
      mainStore.LAYOUT_DATA({
        type: 'side_contents',
        data: {
          path: sideContentPath,
          contents: sideContents.data
        }
      })
    }
    if (bannerHeader) {
      mainStore.LAYOUT_DATA({
        type: 'banner_header',
        data: {
          last_updated_at: Date.now(),
          data: bannerHeader.banner_header_setting
        }
      })
    }
  } catch (e) {
    // init MJ app version
    const appVersion = e.response && e.response.headers && e.response.headers[APP_VERSION_HEADER]
    if (appVersion) mainStore.SET_STATE({ key: 'app_version', value: appVersion })
    return Promise.reject(e)
  }
}

const nuxtServerInit = async nuxtApp => {
  // use user-agent to detect device and init store state
  const app = useNuxtApp()
  const mainStore = useMainStore()
  const route = useRoute()
  const headers = useRequestHeaders()
  const userAgent = new UserAgent(headers)
  if (userAgent.isSmartPhone) {
    mainStore.SET_STATE({ key: 'sp', value: true })
    if (userAgent.isNativeApp) mainStore.SET_STATE({ key: 'native_app', value: true })
    if (userAgent.isTablet) mainStore.SET_STATE({ key: 'tablet', value: true })
    if (userAgent.isIos) mainStore.SET_STATE({ key: 'ios', value: true })
    if (userAgent.isAndroid) mainStore.SET_STATE({ key: 'android', value: true })
    if (userAgent.isAndroidAppVersionOver) mainStore.SET_STATE({ key: 'android_app_version_over', value: true })
  } else {
    mainStore.SET_STATE({ key: 'pc', value: true })
  }

  const cookies = parseCookies(nuxtApp.ssrContext.event)
  const hasNeedSSRCookie = [COOKIE_MJ_SESSION, ...COOKIE_PROFILE_FOR_SEARCH_KEYS, ABTestClientIDService.COOKIE_KEY].some(key => cookies[key])

  if (hasNeedSSRCookie) {
    mainStore.SET_STATE({ key: 'ssr_cookie', value: headers.cookie })
  }

  const mjSession = mainStore.ssrCookie[COOKIE_MJ_SESSION]
  if (mjSession) {
    try {
      await mainStore.dispatch('apiGet', `auth/`).then(res => {
        mainStore.SET_STATE({ key: 'auth_info', value: res.data })
      })
    } catch (e) {
      mainStore.SET_STATE({ key: 'auth_info', value: null })
      handleError(e)
    }
  }

  try {
    await fetchCommonData(app, mainStore, route, userAgent.isSmartPhone)
  } catch (e) {
    handleError(e)
  }

  ABTestClientIDService.setupABTest(mainStore, nuxtApp)
}

export { nuxtServerInit }
