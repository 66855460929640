<template>
  <div v-if="pageDetector.isSearchDetailPage">
    <sp-search :active="true" side="detailSearch" />
  </div>
  <div v-else class="container-wrapper" :class="{ grayBg: !konkatsuFirstTimeAccess }">
    <div class="w-full container">
      <google-translate v-if="pageDetector.isInternationalizationPage" />
      <div class="w-full" :class="{ grayBg: !konkatsuFirstTimeAccess }">
        <DelayHydration>
          <common-flash-message />
        </DelayHydration>
        <notification-message v-if="hasHeaderInformationsData" :header-informations="headerInformationsForEventList" />
        <search-conditions v-if="$store.isSP" mode="header" />
      </div>
    </div>
    <event-list-header
      :search-page-title="searchPageTitle"
      :total-count="totalCount"
      :total-event-online-count="totalEventOnlineCount"
      :total-event-offline-count="totalEventOfflineCount"
      class="opixEventListHeader"
      @changed-tab-from-first-page="enableOpixSending"
    />
    <div class="container">
      <div class="w-full" :class="{ grayBg: !konkatsuFirstTimeAccess }">
        <DelayHydration>
          <event-list
            v-if="!konkatsuFirstTimeAccessSP"
            :events="events"
            :pr-events="pr_events"
            :couplink-store="couplinkEvent1on1Store"
            :total-count="totalCount"
            :count-from="countFrom"
            :has-event-dates="hasEventDates"
            :paging-type="pagingType"
            :custom-paging-style="true"
            :is-empty-search-event-page="events.length === 0"
            :is-search-page="true"
            sales-end-attention-display-mode="search"
            class="opixEvenList"
          />
        </DelayHydration>
        <DelayHydration>
          <div :class="containerClass">
            <search-by-area
              v-if="
                canDisplaySearchByArea && ((!$store.isLoggedIn && !konkatsuFirstTimeAccess) || ($store.isLoggedIn && pageDetector.isInterestsPage))
              "
              :event-type="eventType"
            />
            <!-- Search by area links for csp page -->
            <search-by-area
              v-if="pageDetector.isCsp40sokPage && searchConditions.custom_searches"
              :custom-search="searchConditions.custom_searches[0]"
              :available-areas="availableCspEventsAreas"
            />
            <!-- Search by area links for features page -->
            <search-by-area
              v-if="canDisplaySearchByAreaForFeature"
              :feature-tag="searchConditions.feature_tag"
              :available-areas="availableFeatureEventsAreas"
            />
            <!-- Search by area links for promoters page -->
            <search-by-area
              v-if="canDisplaySearchByAreaForPromoter"
              :promoter="searchConditions.promoter"
              :organizer="searchConditions.organizer"
              :available-areas="availablePromoterEventsAreas"
            />
            <!-- recent checked events -->
          </div>
        </DelayHydration>
        <recommend-by-recent-checked-new
          v-if="!konkatsuFirstTimeAccessSP"
          :prefecture="prefecture"
          :api-search-params="apiSearchParams"
          @recommend-version-recent-checked-new="setRecommendVersionRecentCheckedNew"
          @plus-bookmark-count="applyChangeBookmarkCount(1)"
          @minus-bookmark-count="applyChangeBookmarkCount(-1)"
        />
        <recommend-events-personalized :key="updatePrefecture" :prefecture-slug="prefecture && prefecture.slug" />
        <recent-checked-events
          :display-limit="10"
          class="opixRecentCheckedEvents"
          @click-recent-checked-events="sendOpixClickDatas"
          @plus-bookmark-count="applyChangeBookmarkCount(1)"
          @minus-bookmark-count="applyChangeBookmarkCount(-1)"
        />
        <TopSpRanking
          v-if="pageDetector.isAreaPrefecturePage && ['konkatsu', 'party'].includes(category)"
          :category="category"
          :prefecture="prefecture"
          :area="area"
        />
        <recommend-event-list-new
          v-if="!konkatsuFirstTimeAccessSP"
          :prefecture="prefecture"
          :api-search-params="apiSearchParams"
          @recommend-version-event-list-new="setRecommendVersionEventListNew"
          @plus-bookmark-count="applyChangeBookmarkCount(1)"
          @minus-bookmark-count="applyChangeBookmarkCount(-1)"
        />
        <div v-if="search_by_close_area_links.length && isAreaSearchPage">
          <h2 :class="commonHeadingClasses">
            近隣エリアの街コン
            <span v-if="eventTypeName">・{{ eventTypeName }}</span>
            を探す
          </h2>
          <close-area-link-list :links="search_by_close_area_links" />
        </div>
        <LazySearchEventListWedding v-if="konkatsuFirstTimeAccessSP" />
        <DelayHydration>
          <div class="eventListOtherContent" :class="{ whiteBg: konkatsuFirstTimeAccess, grayBg: !konkatsuFirstTimeAccess }">
            <ranking-popular-events
              v-if="pageDetector.isAreaPrefecturePage"
              :prefecture="prefecture"
              :event-type="category"
              :is-konkatsu-events-pages="pageDetector.isKonkatsuEventsPages"
              :ranking-popular-events="rankingEvents"
              class="opixRankingPopularEvents"
              @click-ranking-popular-events="sendOpixClickDatas"
            />
            <template v-if="pageDetector.isInterestTopPage && pageDetails.activity_lists && !pageDetector.isInterestsPage">
              <h2 :class="commonHeadingClasses" class="opixActivityList">体験・アクティビティーカテゴリー一覧</h2>
              <activity-list :activities="pageDetails.activity_lists" />
            </template>
            <template v-if="pageDetector.isKonkatsuEventsPages">
              <template v-if="canShowKonkatsuPickup">
                <h2 :class="commonHeadingClasses" class="opixKonkatsuPickup">注目の婚活パーティーエリア</h2>
                <konkatsu-pickup :custom-sp-design="true" :class="{ weddingList: konkatsuFirstTimeAccess }" class="konkatsuPickup" />
              </template>
            </template>
            <google-ads v-if="pageDetector.isAreaWithGoogleAdsPage" />
            <template v-if="konkatsuFirstTimeAccess">
              <LazyKonkatsuByGeneration />
            </template>
            <template v-if="isAreaSearchPage && showInternalLinks">
              <h2 :class="commonHeadingClasses" class="internalLink">
                {{ internalLinkHeadingTitle }}
              </h2>
              <internal-link-list
                :prefecture-name="parsePrefectureName"
                :place-slugs="parsePlaceSlugs"
                class="commonMargin"
                @total-event-count="handleTotalEventCount"
              />
            </template>
            <template v-if="pageDetails.ivery">
              <h2 :class="commonHeadingClasses" class="opixIveryList">
                {{ iveryHeadingTitle }}
              </h2>
              <ivery-list :content="pageDetails.ivery" class="iveryListBox commonMargin" />
            </template>
            <template v-if="konkatsuFirstTimeAccessSP">
              <LazyTopForParticipants class="marginT6 commonMargin topSp" :konkatsu-first-time-access="konkatsuFirstTimeAccess" />
              <LazyKonkatsuFaq />
              <LazyTalkWithKonkatsuCounseling />
              <LazyAboutMachiconJapan class="marginB3" />
            </template>
            <category-and-tag-list
              v-if="!konkatsuFirstTimeAccess && !pageDetector.isCategoryPage && !isAreaPage"
              tag-list-from="?from_event-list"
              class="marginB5 commonMargin opixCategoryAndTagList"
            />
            <!-- Shortcut search by age -->
            <top-choose-by-age
              v-if="pageDetector.isAreasCategoriesPage && ['konkatsu', 'party'].includes(category) && prefecture"
              :title="makeChooseByAgeTitle(category, area?.name || prefecture.name)"
              :links="makeChooseByAgeLink(category, prefecture.slug, areaGroup?.slug, area?.slug)"
              class="opixChooseByAge marginT6 commonMargin topSp"
            />
            <side-content-sp :options="{ isEventListPage: true }" class="commonMargin" />
            <search-first-party-guide
              v-if="
                pageDetector.isAreaKonkatsuPage ||
                pageDetector.isAreaPartyPage ||
                pageDetector.isKonkatsuEventsPages ||
                pageDetector.isPartyTopPage ||
                pageDetector.isSimpleAreaPage
              "
              :party-guide="partyGuide"
            />
            <!-- features -->
            <features
              v-if="ads.featured_ads"
              :list="ads.featured_ads"
              :custom-sp-design="$store.isSP"
              :class="{ commonMargin: $store.isSP, marginB5: $store.isSP }"
              class="opixFeatures"
            />
            <!-- Public relations -->
            <public-relations :custom-for-event-detail="true" :custom-sp-design="true" class="commonMargin marginT5 opixPublicRelations nonCard" />
          </div>
        </DelayHydration>
      </div>
    </div>
    <div class="container">
      <div class="paddingT4 width100" :class="{ grayBg: !konkatsuFirstTimeAccess }">
        <breadcrumbs
          v-if="breadcrumb"
          :page="breadcrumb.page"
          :options="breadcrumb.options"
          display-mode="footer"
          class="opixFooterBreadCrumbs breadcrumbContent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EventModel from '@/models/events'
import { findAreaBySlug, findAreaGroupBySlug, findPrefectureBySlug, findRegionBySlug } from '@/models/places'
import UserModel from '@/models/users'
import SearchRoutes from '@/router/searchRoutes'
import { useMainStore } from '@/store'
import GlobalCookie from '@/utils/GlobalCookie'
import PageDetector from '@/utils/PageDetector'
import { makeChooseByAgeLink, makeChooseByAgeTitle } from '@/utils/choose-by-age'
import { SPECIAL_FEATURES, SUPPORT_LANGUAGES } from '@/utils/constants/commons'
import { OPIX_EVENTS_CHECK_POINTS_ON_EVENT_LIST_PAGE, OPIX_LOAD_WAIT_TIME, OPIX_ONLINE_EVENT_TABS } from '@/utils/constants/opixTracking'
import TrackingUserMixin from '@/utils/mixins/TrackingUserMixin'
import { PAGING_TYPE_PATH, PAGING_TYPE_QUERY } from '@/utils/paging'
import SearchData from '@/utils/search/SearchData'
import SearchParams from '@/utils/search/SearchParams'
import sendOpixEvent from '@/utils/sendOpixEvent'
import GTM from '@/utils/tracking/gtm'
import Cookies from 'mj-cookie'
import moment from 'mj-moment'
const EventListHeader = defineAsyncComponent(() => import('@/components/search/EventListHeader.vue'))
const EventList = defineAsyncComponent(() => import('@/components/search/EventList.vue'))
const Breadcrumbs = defineAsyncComponent(() => import('@/components/Breadcrumbs.vue'))
const SideContentPc = defineAsyncComponent(() => import('@/components/side/SideContentPc.vue'))
const SideContentSp = defineAsyncComponent(() => import('@/components/side/SideContentSp.vue'))
const ActivityList = defineAsyncComponent(() => import('@/components/search/ActivityList.vue'))
const KonkatsuPickup = defineAsyncComponent(() => import('@/components/KonkatsuPickup.vue'))
const KonkatsuRanking = defineAsyncComponent(() => import('@/components/KonkatsuRanking.vue'))
const KonkatsuFeatureTags = defineAsyncComponent(() => import('@/components/KonkatsuFeatureTags.vue'))
const GoogleTranslate = defineAsyncComponent(() => import('@/components/GoogleTranslate.vue'))
const SearchByArea = defineAsyncComponent(() => import('@/components/search/SearchByArea.vue'))
const SearchConditions = defineAsyncComponent(() => import('@/components/search/SearchConditions.vue'))
const SimpleSearch = defineAsyncComponent(() => import('@/components/search/SimpleSearch.vue'))
const InternalLinkList = defineAsyncComponent(() => import('@/components/search/InternalLinkList.vue'))
const CloseAreaLinkList = defineAsyncComponent(() => import('@/components/search/CloseAreaLinkList.vue'))
const IveryList = defineAsyncComponent(() => import('@/components/IveryList.vue'))
const RecommendationServices = defineAsyncComponent(() => import('@/components/banner/RecommendationServices.vue'))
const TieUp = defineAsyncComponent(() => import('@/components/banner/TieUp.vue'))
const CommonFlashMessage = defineAsyncComponent(() => import('@/components/CommonFlashMessage.vue'))
const AddLineFriend = defineAsyncComponent(() => import('@/components/AddLineFriend.vue'))
const CouplinkMobileApps = defineAsyncComponent(() => import('@/components/CouplinkMobileApps.vue'))
const Features = defineAsyncComponent(() => import('@/components/banner/Features.vue'))
const RecentCheckedEvents = defineAsyncComponent(() => import('@/components/RecentCheckedEvents.vue'))
const RankingPopularEvents = defineAsyncComponent(() => import('@/components/search/RankingPopularEvents.vue'))
const CategoryAndTagList = defineAsyncComponent(() => import('@/components/top/CategoryAndTagList.vue'))
const PublicRelations = defineAsyncComponent(() => import('@/components/banner/PublicRelations.vue'))
const SliderEventList = defineAsyncComponent(() => import('@/components/event/SliderEventList.vue'))
const RecommendEventList = defineAsyncComponent(() => import('@/components/event/RecommendEventList.vue'))
const RecommendEventListNew = defineAsyncComponent(() => import('@/components/search/RecommendEventListNew.vue'))
const RecommendByRecentCheckedNew = defineAsyncComponent(() => import('@/components/search/RecommendByRecentCheckedNew.vue'))
const RecommendEventsPersonalized = defineAsyncComponent(() => import('@/components/RecommendEventsPersonalized.vue'))
const GoogleAds = defineAsyncComponent(() => import('@/components/GoogleAds.vue'))
const NotificationMessage = defineAsyncComponent(() => import('@/components/NotificationMessage.vue'))
const TopSpRanking = defineAsyncComponent(() => import('@/components/top/TopSpRanking.vue'))
const TopChooseByAge = defineAsyncComponent(() => import('@/components/top/ChooseByAge.vue'))

const SpSearch = defineAsyncComponent(() => import('@/components/search/SpSearch.vue'))
const WEEK_DAYS = {
  sun: '日曜日',
  mon: '月曜日',
  tue: '火曜日',
  wed: '水曜日',
  thu: '木曜日',
  fri: '金曜日',
  sat: '土曜日'
}
const GTM_PUSH_PRODUCTS_COUNT = 3
export default defineNuxtComponent({
  components: {
    RecommendEventListNew,
    RecommendByRecentCheckedNew,
    EventListHeader,
    EventList,
    SimpleSearch,
    SideContentPc,
    SideContentSp,
    Breadcrumbs,
    ActivityList,
    KonkatsuPickup,
    KonkatsuRanking,
    KonkatsuFeatureTags,
    SearchByArea,
    SearchConditions,
    InternalLinkList,
    CloseAreaLinkList,
    IveryList,
    RecommendationServices,
    TieUp,
    CommonFlashMessage,
    AddLineFriend,
    CouplinkMobileApps,
    Features,
    RecentCheckedEvents,
    RankingPopularEvents,
    SpSearch,
    CategoryAndTagList,
    PublicRelations,
    RecommendEventList,
    SliderEventList,
    GoogleTranslate,
    RecommendEventsPersonalized,
    GoogleAds,
    NotificationMessage,
    TopSpRanking,
    TopChooseByAge
  },
  mixins: [TrackingUserMixin],
  props: {
    searchResult: { type: Object, default: () => ({}), required: false },
    hideBreadCrumb: { type: Boolean, default: false, required: false }
  },
  async setup() {
    const app = useNuxtApp()
    const store = useMainStore()
    const route = useRoute()

    const requests = []
    const headerInformations = ref({})

    definePageMeta({
      middleware: [
        'redirect-search-by-date-path',
        'redirect-child-interests',
        'redirect-area',
        'redirect-interests',
        'redirect-online-event',
        'validate-area-page'
      ],
      validate(route) {
        return !route.params.lang || SUPPORT_LANGUAGES[route.params.lang]
      }
    })
    const fetchData = async () => {
      const headerInformationsRequest = store.dispatch('apiGetV2', 'announcements/header_informations').then(response => {
        return {
          headerInformationsForEventList: response.data.header_informations.filter(item => item.show_header_on_event_list === true)
        }
      })
      requests.push(headerInformationsRequest)
      if (!store.$state.search_page.is_to_search_page_from_event_page) {
        requests.push(new SearchData(store, route).fetchData())
      }

      try {
        const [headerInfos] = await Promise.all(requests)
        headerInformations.value = headerInfos
      } catch (e) {
        const errorCode = parseInt(e.response && e.response.status)
        await app.$error(errorCode)
      }
    }

    //TO DO: need refactor that
    function calculateComputedValue() {
      const computedValue = {}
      computedValue.$route = route
      computedValue.$store = store

      const searchParams = new SearchParams(store, route)
      computedValue.apiSearchParams = searchParams.apiSearchParams
      computedValue.query = route.query
      computedValue.params = route.params
      computedValue.searchConditions = store.$state.search_page.search_conditions || {}

      function area(that) {
        const slug = that.apiSearchParams['filter[area]'] ? that.apiSearchParams['filter[area]'].split(',')[0] : ''
        if (!slug) return null
        return findAreaBySlug(slug)
      }
      function areaGroup(that) {
        const slug = that.apiSearchParams['filter[area_group]'] ? that.apiSearchParams['filter[area_group]'].split(',')[0] : ''
        if (!slug) return null
        return findAreaGroupBySlug(slug)
      }
      function prefecture(that) {
        const slug = that.apiSearchParams['filter[prefecture]'] ? that.apiSearchParams['filter[prefecture]'].split(',')[0] : ''
        if (!slug) return null
        return findPrefectureBySlug(slug)
      }
      computedValue.area = area(computedValue)
      computedValue.areaGroup = areaGroup(computedValue)
      computedValue.prefecture = prefecture(computedValue)
      function place(that) {
        const regionSlug =
          that.apiSearchParams['filter[region]'] || (that.apiSearchParams['filter[regions]'] && that.apiSearchParams['filter[regions]'].split(',')[0])

        if (that.area) return that.area
        if (that.areaGroup) return that.areaGroup
        if (that.prefecture) return that.prefecture
        if (regionSlug) return findRegionBySlug(regionSlug)
        return null
      }
      computedValue.place = place(computedValue)
      return computedValue
    }

    function searchPageTitle(that) {
      const conditions = []
      const areaCondition = that.place ? (that.params.region ? `${that.place.name}地方` : `${that.place.name}`) : ''
      const wDays = that.apiSearchParams['filter[start_at][weekday_in]']
      const contentTypes = that.apiSearchParams['filter[content_type]']
      const keyword = that.apiSearchParams.s ? that.apiSearchParams.s.trim() : ''
      const featureTag = that.apiSearchParams['filter[feature_tag]']
      const catIns = that.$route.query['cat_in[]']

      if (that.isWeekendEventsPage && featureTag !== 'tomokatsu') conditions.push('今週末開催')
      if (that.isNextWeekendEventsPage && featureTag !== 'tomokatsu') conditions.push('来週末')
      if (wDays && !wDays.includes(',')) conditions.push(WEEK_DAYS[wDays])
      if (areaCondition) conditions.push(areaCondition)
      if (that.apiSearchParams.user_gender) conditions.push(`${UserModel.getGenderText(that.apiSearchParams.user_gender)}参加`)
      if (contentTypes && !contentTypes.includes(',')) conditions.push(EventModel.getContentTypeValue(contentTypes))
      if (that.searchConditions.feature_tag) conditions.push(that.searchConditions.feature_tag.name)
      if (keyword) conditions.push(keyword)
      if (that.eventType && featureTag !== 'tomokatsu') conditions.push(that.$useI18(`event.attributes.event_type.${that.eventType}`))
      if (!that.eventType && that.searchConditions.interests) conditions.push('趣味コン')
      if (conditions.length === 0) conditions.push('条件絞込み')
      if (catIns) {
        if (catIns instanceof Array || featureTag === 'counseling') return `【条件絞込み】`
        const purpose = EventModel.getListPurposes().find(v => v.slug === catIns)
        if (purpose) return `【${purpose.name}】`
        return ''
      }
      if (SPECIAL_FEATURES.includes(featureTag)) {
        const purpose = EventModel.getListPurposes().find(v => v.slug === featureTag)
        if (purpose) return `【${purpose.name}】`
      }
      return conditions.join('/')
    }

    store.$state.error_code == 404 ? fetchData() : await fetchData()

    useHeadParams({
      $route: route,
      isEventListPage: true,
      totalCount: store.$state.search_page.total_count || 0,
      searchPageTitle: searchPageTitle(calculateComputedValue())
    })

    watch(
      () => [route.fullPath],
      async () => {
        await fetchData()
        useHeadParams({
          $route: route,
          isEventListPage: true,
          totalCount: store.$state.search_page.total_count || 0,
          searchPageTitle: searchPageTitle(calculateComputedValue())
        })
      }
    )
    return { headerInformationsForEventList: headerInformations.value.headerInformationsForEventList }
  },
  data() {
    return {
      isEventListPage: true,
      baseCheckPoints: [...OPIX_EVENTS_CHECK_POINTS_ON_EVENT_LIST_PAGE],
      recommendVersionEventListNew: null,
      recommendVersionRecentCheckedNew: null,
      updatePrefecture: 0,
      canSendSearchResultOpixEvent: true,
      showInternalLinks: true
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'events-code') {
      this.$store.SET_STATE_BY_PATH({ path: ['search_page', 'savedPosition'], value: this.$store.$state.window.scroll_top })
    } else {
      this.$store.SET_STATE_BY_PATH({ path: ['search_page', 'savedPosition'], value: 0 })
      this.$store.SET_STATE_BY_PATH({ path: ['search_page', 'store'], value: [] })
    }
    next()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (SearchRoutes.ListSearchEventsRoutesSet.includes(to.name) && from.name === 'events-code') {
        const savedPosition = vm.$store.$state.search_page.savedPosition
        setTimeout(() => {
          window.scrollTo(0, savedPosition)
        }, 1)
      }
    })
  },
  computed: {
    containerClass() {
      return this.pageDetector.isInterestsPage ? 'interestContainer' : 'commonMargin'
    },
    konkatsuFeatureTagBanners() {
      if (!this.pageDetails.konkatsu_feature_tags) return []
      return this.pageDetails.konkatsu_feature_tags.map(v => {
        return {
          link: { name: 'features-events', params: { feature_tag: v.slug } },
          image: v.banner,
          imageAlt: v.name
        }
      })
    },
    events() {
      return this.$store.$state.search_page.events || []
    },
    pr_events() {
      return this.$store.$state.search_page.pr_events || []
    },
    couplinkEvent1on1Store() {
      return this.$store.$state.search_page.couplink_event_1on1_store
    },
    search_by_close_area_links() {
      const searchByCloseAreaLinks = this.$store.$state.search_page.search_by_close_area_links || {}
      if (this.place === this.area) {
        return searchByCloseAreaLinks.area_links || []
      } else if (this.place === this.prefecture) {
        return searchByCloseAreaLinks.prefecture_links || []
      }
      return []
    },
    rankingEvents() {
      return this.$store.$state.search_page.ranking_events || []
    },
    totalCount() {
      return this.$store.$state.search_page.total_count || 0
    },
    countFrom() {
      return this.$store.$state.search_page.count_from || 0
    },
    hasEventDates() {
      return this.$store.$state.search_page.has_event_dates || []
    },
    totalEventOnlineCount() {
      return this.$store.$state.search_page.total_event_online_count || 0
    },
    totalEventOfflineCount() {
      return this.$store.$state.search_page.total_event_offline_count || 0
    },
    ads() {
      return this.$store.$state.search_page.ads || {}
    },
    pageDetails() {
      return this.$store.$state.search_page.page_details || {}
    },
    commonHeadingClasses() {
      const classes = []
      if (this.konkatsuFirstTimeAccess) {
        classes.push('commonKonkatsuHeading', 'marginT5', 'marginB4')
      } else {
        classes.push('commonHeadingBlack', 'commonMargin')
      }
      if (this.pageDetector.isInterestTopPage && !this.$store.isSP) classes.push('alignCenter')
      return classes
    },
    pageDetector() {
      return new PageDetector(this.$route)
    },
    params() {
      return this.$route.params
    },
    query() {
      return this.$route.query
    },
    pagingType() {
      return this.pageDetector.isSearchEventsFromTopPage ? PAGING_TYPE_QUERY : PAGING_TYPE_PATH
    },
    // search conditions (values) that returned from api
    searchConditions() {
      return this.$store.$state.search_page.search_conditions || {}
    },
    // convert params/query params to api search conditions
    apiSearchParams() {
      const searchParams = new SearchParams(this.$store, this.$route)
      return searchParams.apiSearchParams
    },
    eventType() {
      const eventTypes = this.apiSearchParams['filter[event_type]'] || ''
      return eventTypes.includes(',') ? '' : eventTypes
    },
    eventTypeName() {
      const eventType = this.pageDetector.isInterestEventsPage ? 'interest' : this.eventType
      return eventType === 'konkatsu' ? '婚活パーティー' : EventModel.getEventTypeName(eventType)
    },
    category() {
      return this.params.category ? this.params.category : ''
    },
    canDisplaySearchByArea() {
      if (this.eventType && this.eventType !== 'machicon' && !this.place) return true
      // the areas top page (/areas)
      if (this.pageDetector.isAreasTopPage) return true
      // the interests top page (/interests/)
      if (this.pageDetector.isInterestTopPage) return true
      // have interest condition
      if (this.apiSearchParams['filter[have_interest]'] === 1 && !this.query['cat[]'] && !this.query['interest']) return true
      return this.pageDetector.isFixedEventsPage
    },
    canDisplaySearchByAreaForFeature() {
      return this.pageDetector.isFeaturesEventsPage && this.searchConditions.feature_tag && !this.$route.name.includes('areas')
    },
    canDisplaySearchByAreaForPromoter() {
      return this.pageDetector.isPromoterDetailPage
    },
    availableFeatureEventsAreas() {
      return this.pageDetails.available_feature_events_areas || []
    },
    availablePromoterEventsAreas() {
      return this.pageDetails.available_promoter_events_areas || []
    },
    place() {
      const regionSlug =
        this.apiSearchParams['filter[region]'] || (this.apiSearchParams['filter[regions]'] && this.apiSearchParams['filter[regions]'].split(',')[0])

      if (this.area) return this.area
      if (this.areaGroup) return this.areaGroup
      if (this.prefecture) return this.prefecture
      if (regionSlug) return findRegionBySlug(regionSlug)
      return null
    },
    area() {
      const slug = this.apiSearchParams['filter[area]'] ? this.apiSearchParams['filter[area]'].split(',')[0] : ''
      if (!slug) return null
      return findAreaBySlug(slug)
    },
    areaGroup() {
      const slug = this.apiSearchParams['filter[area_group]'] ? this.apiSearchParams['filter[area_group]'].split(',')[0] : ''
      if (!slug) return null
      return findAreaGroupBySlug(slug)
    },
    prefecture() {
      const slug = this.apiSearchParams['filter[prefecture]'] ? this.apiSearchParams['filter[prefecture]'].split(',')[0] : ''
      if (!slug) return null
      return findPrefectureBySlug(slug)
    },
    parsePrefectureName() {
      if (this.pageDetector.isAreaWithCityOrTownPage) return this.area.name
      if (this.pageDetector.isAreaGroupPage) return this.areaGroup.name
      return this.prefecture.name.replace(/[都府県]$/, '')
    },
    parsePlaceSlugs() {
      if (this.pageDetector.isAreaWithCityOrTownPage) return [this.prefecture.slug, this.areaGroup.slug, this.area.slug]
      if (this.pageDetector.isAreaGroupPage) return [this.prefecture.slug, this.areaGroup.slug]
      return [this.prefecture.slug]
    },
    isNextWeekendEventsPage() {
      const currentDate = this.params.start_date ? moment(this.params.start_date) : moment()
      const nextSaturday = currentDate.add(7, 'days').endOf('week')
      const nextSunday = moment(nextSaturday).add(1, 'days')
      return (
        Number(this.query['from-year']) === nextSaturday.year() &&
        Number(this.query['from-month']) === nextSaturday.month() + 1 &&
        Number(this.query['from-day']) === nextSaturday.date() &&
        Number(this.query['to-year']) === nextSunday.year() &&
        Number(this.query['to-month']) === nextSunday.month() + 1 &&
        Number(this.query['to-day']) === nextSunday.date()
      )
    },
    isWeekendEventsPage() {
      const currentDate = this.params.start_date ? moment(this.params.start_date) : moment()
      const saturday = currentDate.endOf('week')
      const sunday = moment(saturday).add(1, 'days')
      return (
        Number(this.query['from-year']) === saturday.year() &&
        Number(this.query['from-month']) === saturday.month() + 1 &&
        Number(this.query['from-day']) === saturday.date() &&
        Number(this.query['to-year']) === sunday.year() &&
        Number(this.query['to-month']) === sunday.month() + 1 &&
        Number(this.query['to-day']) === sunday.date()
      )
    },
    // the title of search detail result page
    searchPageTitle() {
      const conditions = []
      const areaCondition = this.place ? (this.params.region ? `${this.place.name}地方` : `${this.place.name}`) : ''
      const wDays = this.apiSearchParams['filter[start_at][weekday_in]']
      const contentTypes = this.apiSearchParams['filter[content_type]']
      const keyword = this.apiSearchParams.s ? this.apiSearchParams.s.trim() : ''
      const featureTag = this.apiSearchParams['filter[feature_tag]']
      const catIns = this.$route.query['cat_in[]']

      if (this.isWeekendEventsPage && featureTag !== 'tomokatsu') conditions.push('今週末開催')
      if (this.isNextWeekendEventsPage && featureTag !== 'tomokatsu') conditions.push('来週末')
      if (wDays && !wDays.includes(',')) conditions.push(WEEK_DAYS[wDays])
      if (areaCondition) conditions.push(areaCondition)
      if (this.apiSearchParams.user_gender) conditions.push(`${UserModel.getGenderText(this.apiSearchParams.user_gender)}参加`)
      if (contentTypes && !contentTypes.includes(',')) conditions.push(EventModel.getContentTypeValue(contentTypes))
      if (this.searchConditions.feature_tag) conditions.push(this.searchConditions.feature_tag.name)
      if (keyword) conditions.push(keyword)
      if (this.eventType && featureTag !== 'tomokatsu') conditions.push(this.$useI18(`event.attributes.event_type.${this.eventType}`))
      if (!this.eventType && this.searchConditions.interests) conditions.push('趣味コン')
      if (conditions.length === 0) conditions.push('条件絞込み')
      if (catIns) {
        if (catIns instanceof Array || featureTag === 'counseling') return `【条件絞込み】`
        const purpose = EventModel.getListPurposes().find(v => v.slug === catIns)
        if (purpose) return `【${purpose.name}】`
        return ''
      }
      if (SPECIAL_FEATURES.includes(featureTag)) {
        const purpose = EventModel.getListPurposes().find(v => v.slug === featureTag)
        if (purpose) return `【${purpose.name}】`
      }
      return conditions.join('/')
    },
    breadcrumbEventType() {
      if (this.pageDetector.isActivityTopPage) return 'activity'
      if (this.searchConditions.activity_theme || this.params.activity_theme) {
        return this.searchConditions.activity_theme ? this.searchConditions.activity_theme.slug : this.params.activity_theme
      }
      const eventType = this.apiSearchParams['filter[event_type]']
      if (eventType) {
        // custom event type
        if (eventType === 'gocon_dinnerplus_sakunomi' || eventType === 'tomokatsu') return null
        return eventType.replace(',', '_')
      }
      if (this.pageDetector.isFeaturesEventsPage && this.searchConditions.feature_tag) {
        return this.searchConditions.feature_tag.breadcrumb || this.searchConditions.feature_tag.name
      }
      if (this.pageDetector.isInterestChildPage && this.searchConditions.interests) return this.searchConditions.interests[0].name
      if (this.pageDetector.isInterestEventsPage) return 'interests'
      return null
    },
    commonBreadcrumbOptions() {
      const commonOptions = { eventType: this.breadcrumbEventType, showEventType: true }
      if (this.searchConditions.promoter) commonOptions.promoter = this.searchConditions.promoter
      if (this.searchConditions.organizer) commonOptions.organizer = this.searchConditions.organizer
      if (this.searchConditions.activity_theme) commonOptions.activityTheme = this.searchConditions.activity_theme
      if (this.searchConditions.interests) commonOptions.interest = this.searchConditions.interests[0]
      if (this.searchConditions.feature_tag) commonOptions.featureTag = this.searchConditions.feature_tag
      return commonOptions
    },
    datesPageBreadcrumb() {
      const options = {}
      if (this.params.area) {
        options.areaType = 'area'
        options.areaSlug = this.params.area
      } else if (this.params.area_group) {
        options.areaType = 'areaGroup'
        options.areaSlug = this.params.area_group
      } else if (this.params.prefecture) {
        options.areaType = 'prefecture'
        options.areaSlug = this.params.prefecture
      } else if (this.params.region) {
        options.areaType = 'region'
        options.areaSlug = this.params.region
      }

      if (this.params.day) {
        options.dateType = 'day'
        const month = `0${this.params.month}`.slice(-2)
        const day = `0${this.params.day}`.slice(-2)
        options.date = `${this.params.year}-${month}-${day}`
      } else if (this.params.month) {
        options.dateType = 'month'
        const month = `0${this.params.month}`.slice(-2)
        options.date = `${this.params.year}-${month}-01`
      } else {
        options.dateType = 'year'
        options.date = `${this.params.year}-01-01`
      }
      return { page: 'date', options: { ...this.commonBreadcrumbOptions, ...options } }
    },
    breadcrumb() {
      if (this.hideBreadCrumb) return null
      if (this.pageDetector.isAreasTopPage) return { page: 'areasTop' }
      // search by keyword page
      if (this.apiSearchParams['filter[keyword]']) {
        return {
          page: 'searchByKeyword',
          options: { keyword: this.apiSearchParams['filter[keyword]'] }
        }
      }
      // search detail page (search form)
      if (this.pageDetector.isSearchDetailPage) return { page: 'detailSearch' }
      // search detail result page
      if (this.pageDetector.isSearchEventsPage) {
        return {
          page: 'detailSearchResult',
          options: { pageTitle: this.searchPageTitle, query: this.query }
        }
      }
      if (this.searchConditions.custom_searches) {
        if (this.pageDetector.isAreasCustomSearchPage) {
          return {
            page: 'areasCustomSearch',
            options: {
              ...this.commonBreadcrumbOptions,
              prefectureSlug: this.params.prefecture,
              customSearch: this.searchConditions.custom_searches[0]
            }
          }
        }
        if (this.pageDetector.isRegionsCustomSearchPage) {
          return {
            page: 'regionsCustomSearch',
            options: { regionSlug: this.params.region, customSearch: this.searchConditions.custom_searches[0] }
          }
        }
        if (this.pageDetector.isAreasAreaCustomSearchPage) {
          return {
            page: 'areasAreaCustomSearch',
            options: { areaSlug: this.params.area, customSearch: this.searchConditions.custom_searches[0] }
          }
        }
        return {
          page: 'customSearch',
          options: { customSearch: this.searchConditions.custom_searches[0] }
        }
      }
      // dates page (/dates/2019/03/01)
      if (this.apiSearchParams['filter[start_at_or_end_at][gte]'] && this.apiSearchParams['filter[start_at_or_end_at][lt]']) {
        return this.datesPageBreadcrumb
      }
      // areas page has format /areas/tokyo/abc/xyz
      if (this.params.area) {
        return {
          page: 'area',
          options: { ...this.commonBreadcrumbOptions, areaSlug: this.params.area }
        }
      }
      // areas page has format /areas/tokyo/abc
      if (this.params.area_group) {
        return {
          page: 'areaGroup',
          options: { ...this.commonBreadcrumbOptions, areaGroupSlug: this.params.area_group }
        }
      }
      // fixed events page
      if (this.pageDetector.isFixedEventsPage) {
        return {
          page: 'fixedEvent',
          options: { prefectureSlug: this.params.prefecture }
        }
      }
      // areas page has format /areas/tokyo
      if (this.params.prefecture) {
        return {
          page: 'prefecture',
          options: { ...this.commonBreadcrumbOptions, prefectureSlug: this.params.prefecture }
        }
      }
      // regions page (ex: regions/kanto)
      if (this.params.region) {
        return {
          page: 'region',
          options: { ...this.commonBreadcrumbOptions, regionSlug: this.params.region }
        }
      }
      // interests page has format /interests/abcxyz or /interests/abcxyz/xxx
      if (this.pageDetector.isInterestChildPage && this.searchConditions.interests) {
        const interest = this.searchConditions.interests[0]
        return { page: 'interestChild', options: { interest } }
      }
      // activity theme page
      if (this.searchConditions.activity_theme) {
        return { page: 'activityTheme', options: { activityTheme: this.searchConditions.activity_theme } }
      }
      // features tomokatsu page
      if (this.pageDetector.isFeaturesEventsPage && this.searchConditions.feature_tag && this.searchConditions.feature_tag.slug === 'tomokatsu') {
        return {
          page: 'eventType',
          options: { eventType: '友達作り・友達探し(社会人サークル・オフ会・友活)', featureTag: this.searchConditions.feature_tag }
        }
      }
      // promoter events page
      if (this.searchConditions.promoter && this.searchConditions.organizer) {
        return { page: 'promoterEvents', options: this.commonBreadcrumbOptions }
      }
      if (this.breadcrumbEventType) {
        return {
          page: 'eventType',
          options: { eventType: this.breadcrumbEventType, featureTag: this.commonBreadcrumbOptions.featureTag }
        }
      }
      return null
    },
    // area のページかどうかを確認します
    // /areas/xxxx, /areas/xxxx/ag0000, /areas/xxxx/ag0000/yyyy
    isAreaSearchPage() {
      const isSpecificAreaPage = this.pageDetector.isSpecificAreaPage && this.prefecture
      const isAreaWithCityOrTownPage = this.pageDetector.isAreaWithCityOrTownPage ? this.area && this.areaGroup : true
      const isAreaGroupPage = this.pageDetector.isAreaGroupPage ? this.areaGroup : true

      return isSpecificAreaPage && isAreaWithCityOrTownPage && isAreaGroupPage
    },
    internalLinkHeadingTitle() {
      return `${this.parsePrefectureName}の特集イベント`
    },
    iveryHeadingTitle() {
      return `${this.pageDetails.ivery.h2name === '街コン' ? '街コン・婚活' : this.pageDetails.ivery.h2name}コラム`
    },
    criteoGtmData() {
      return this.$store.$state.criteo_gtm_data
    },
    globalCookie() {
      return new GlobalCookie(this.$store)
    },
    konkatsuFirstTimeAccess() {
      return this.globalCookie.firstTimeAccessPage && this.pageDetector.isKonkatsuEventsPages
    },
    konkatsuFirstTimeAccessSP() {
      return this.konkatsuFirstTimeAccess && !this.$store.isLoggedIn
    },
    canShowKonkatsuPickup() {
      return !this.konkatsuFirstTimeAccessSP
    },
    tempPrefecture() {
      if (this.prefecture) return this.prefecture.slug
      return this.globalCookie.get('prefecture') ? this.globalCookie.get('prefecture') : null
    },
    tempGender() {
      if (this.apiSearchParams['user_gender']) return this.apiSearchParams['user_gender']
      if (this.globalCookie.get('gender')) return this.globalCookie.get('gender')
      return this.$store.myGender !== '' ? this.$store.myGender : null
    },
    tempAge() {
      if (this.apiSearchParams['user_age']) return this.apiSearchParams['user_age']
      if (this.globalCookie.get('age')) return this.globalCookie.get('age')
      return this.$store.myAge ? this.$store.myAge.toString() : null
    },
    currentEventTypeTab() {
      return OPIX_ONLINE_EVENT_TABS[this.$store.$state.search_page.on_online_event_tab] || ''
    },
    partyGuide() {
      if (this.pageDetector.isAreaKonkatsuPage || this.pageDetector.isKonkatsuEventsPages || this.pageDetector.isSimpleAreaPage) return 'konkatsu'
      if (this.pageDetector.isAreaPartyPage || this.pageDetector.isPartyTopPage) return 'koikatsu'
    },
    isAreaPage() {
      return this.pageDetector.isAreasDetailPage || this.pageDetector.isSearchWithPrefOrAreaPage
    }
  },
  watch: {
    searchResult: {
      handler(value) {
        this.setData(value)
      },
      deep: true
    },
    '$route.query'() {
      this.pushGtmData()
    },
    prefecture() {
      this.updatePrefecture += 1
    }
  },
  created() {
    this.$store.SET_STATE({ key: 'is_search_page', value: true })
    if (this.pageDetector.isSearchDetailPage) this.canSendSearchResultOpixEvent = false
  },
  mounted() {
    if (this.pageDetector.isSpecificAreaPage && this.prefecture) Cookies.set('prefecture', this.prefecture.slug)
    this.pushGtmData()
    if (this.pageDetector.isInternationalizationPage) {
      document.getElementsByTagName('title')[0].classList.add('notranslate')
      if (!window.location.hash) {
        const locale = SUPPORT_LANGUAGES[this.$route.params.lang]
        window.location = window.location + `#googtrans(ja|${locale})`
      }
    }
    if (!window.opix) {
      setTimeout(() => {
        this.sendMJCLFooterDatas('show')
        this.sendSearchResultOpixDatas()
      }, OPIX_LOAD_WAIT_TIME)
    } else {
      this.sendMJCLFooterDatas('show')
      this.sendSearchResultOpixDatas()
    }
  },
  updated() {
    if (this.pageDetector.isSearchDetailPage) this.canSendSearchResultOpixEvent = false
    this.sendSearchResultOpixDatas()
  },
  destroyed() {
    this.$store.SET_STATE({ key: 'is_search_page', value: false })
  },
  methods: {
    setData(data) {
      Object.keys(data).forEach(key => {
        this.$data[key] = data[key]
      })
    },
    pushGtmData() {
      if (!this.pageDetector.isSearchDetailPage) {
        const gtm = new GTM(this.$route, this.$store)
        GTM.pushData(gtm.defaultData)
        GTM.pushData({
          PageType: 'Listingpage',
          HashedEmail: this.criteoGtmData.hashed_email,
          ProductIDList: this.events.slice(0, GTM_PUSH_PRODUCTS_COUNT).map(e => e.id),
          Ui_userlv: this.criteoGtmData.ui_userlv
        })
        GTM.trackingPageView(this.$route)
      }
    },
    applyChangeBookmarkCount(num) {
      this.bookmarkedUserCount += num
    },
    setRecommendVersionRecentCheckedNew(value) {
      this.recommendVersionRecentCheckedNew = value
    },
    setRecommendVersionEventListNew(value) {
      this.recommendVersionEventListNew = value
    },
    sendSearchResultOpixDatas() {
      if (!this.canSendSearchResultOpixEvent) return
      let lastRoute = null
      if (this.$store.$state.is_tab_changed) {
        lastRoute = import.meta.env.ROOT_URL + this.$route.fullPath // Because clicking tab doesn't change URL
      } else {
        lastRoute = this.$store.$state.previous_page_path ? import.meta.env.ROOT_URL + this.$store.$state.previous_page_path : null
      }
      const opixDatas = {
        userID: this.$store.isLoggedIn ? this.$store.$state.my_profile.id : null,
        currentRoute: import.meta.env.ROOT_URL + this.$route.fullPath,
        lastRoute: lastRoute,
        hitNum: this.totalCount.toString(),
        tmpPrefecture: this.tempPrefecture,
        tmpGender: this.tempGender,
        tmpAge: this.tempAge,
        tab: this.currentEventTypeTab
      }
      sendOpixEvent('search_results', opixDatas)
      this.canSendSearchResultOpixEvent = false
    },
    enableOpixSending() {
      this.canSendSearchResultOpixEvent = true
    },
    hasHeaderInformationsData() {
      return headerInformationsForEventList.length > 0
    },
    handleTotalEventCount(totalEventCount) {
      this.showInternalLinks = Object.values(totalEventCount).some(val => val > 0)
    },
    makeChooseByAgeLink,
    makeChooseByAgeTitle
  }
})
</script>

<style lang="scss" scoped>
.container {
  margin-top: 0;
  margin-bottom: 0;
  &.single {
    &.noMarginBottom {
      margin-bottom: 0;
    }
  }
}

.container-wrapper {
  padding-bottom: 1.5rem;
}

.commonMargin {
  margin-left: $spCommonMargin;
  margin-right: $spCommonMargin;
}

.grayBg {
  background: $gray-f7;
}

.width100 {
  width: 100%;
}

@include sp {
  .container {
    margin-bottom: 0;
  }
  .breadcrumbContent {
    margin: 0 0.6rem;
    padding-top: 0.5rem;
    padding-bottom: 2.5rem;
  }
}

:deep() {
  @include sp {
    .areaHeader {
      &-headingTitle {
        background: $white;
      }
    }
    .interestNav {
      background: $white;
    }
    .eventListOtherContent {
      .serviceList {
        margin-left: $spCommonMargin;
        margin-right: $spCommonMargin;
      }
      .konkatsuPickup {
        margin: 0.5rem 1.1rem 0;
        .buttonList-item a {
          font-size: 1.4rem;
          font-weight: 700;
        }
      }
      .weddingList {
        padding-bottom: 1rem;
      }
    }
    .iveryListBox.contentBlock {
      box-shadow: 0 0 3px $gray-c6;
      border-radius: 1.2rem;
      padding: 1.5rem;
      .iveryList {
        padding: 0;
      }
      .marginX3 a {
        font-size: 1.4rem;
        font-weight: 700;
        color: $gray-55;
      }
    }
    .clApps {
      margin-left: 0.6rem;
      margin-right: 0.6rem;
    }
    .addLineFriend {
      margin-left: 0.6rem;
      margin-right: 0.6rem;
    }
    .recentCheckedEvents,
    .rankingPopularEvents {
      margin-top: 0;
      padding-bottom: 0;
      h2 {
        margin-left: $spCommonMargin;
      }
    }
    .eventsList {
      margin-left: $spCommonMargin;
    }
    .eventList {
      .eventItem {
        padding: 0;
      }
    }
  }
  .breadcrumbContent {
    margin-top: 0;
    margin-bottom: 6rem;
    padding-top: 0;
    padding-bottom: 0;
    .breadcrumbList {
      box-shadow: 0 0 3px $gray-c6;
      border-radius: 3px;
    }
  }

  @include pc {
    .eventList {
      display: flex;
      gap: 1.5rem;
      flex-wrap: wrap;
      .eventList-item {
        width: calc((100% - 3rem) / 3);
        margin-bottom: 0;
      }
      .eventItem {
        padding: 0;
        .eventItem-container {
          min-height: 13rem;
        }
        .eventItem-head {
          padding: 0 1rem;
        }
      }
    }
  }
  .commonHeadingBlackSP,
  .commonHeadingBlack {
    margin-top: 4rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    @include pc {
      text-align: center;
    }
  }
  .spAreaHeader,
  .tabList {
    background-color: transparent !important;
  }
}

.interestContainer {
  padding: 0 1rem;
}

.nonCard :deep() {
  .serviceList,
  .contentBox,
  .bannerList,
  .serviceList.forCustomDesignPage {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    img {
      border-radius: 1.2rem;
      border: 1px solid $gray-c6;
    }
  }
}

.w-full {
  width: 100%;
}

.whiteBg {
  background: $white;
}

.internalLink {
  text-align: left;
}
</style>
