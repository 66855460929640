import { default as cancel_membership_confirm2L1T6XQxRCMeta } from "/var/www/mj_client/releases/20250127060019/pages/accounts/cancel_membership_confirm.vue?macro=true";
import { default as amazon_callbackefEOUYXY38Meta } from "/var/www/mj_client/releases/20250127060019/pages/amazon_callback.vue?macro=true";
import { default as _91area_group_93gdRVQMasT5Meta } from "/var/www/mj_client/releases/20250127060019/pages/area_group_rankings/[area_group].vue?macro=true";
import { default as _91area_93kmBk1gggG9Meta } from "/var/www/mj_client/releases/20250127060019/pages/area_rankings/[area].vue?macro=true";
import { default as editu3cx1OvjJ6Meta } from "/var/www/mj_client/releases/20250127060019/pages/bank_accounts/edit.vue?macro=true";
import { default as index45oLHsvPA9Meta } from "/var/www/mj_client/releases/20250127060019/pages/biz_inquiry/index.vue?macro=true";
import { default as thanked_inquiryVhfCKu2TtBMeta } from "/var/www/mj_client/releases/20250127060019/pages/biz_inquiry/thanked_inquiry.vue?macro=true";
import { default as receivejYKaeMutOIMeta } from "/var/www/mj_client/releases/20250127060019/pages/check_in_tickets/receive.vue?macro=true";
import { default as _91id_93WCrHK7Hki0Meta } from "/var/www/mj_client/releases/20250127060019/pages/cl_events/dating_events/[id].vue?macro=true";
import { default as index2trG4K6QhnMeta } from "/var/www/mj_client/releases/20250127060019/pages/cl_events/dating_events/index.vue?macro=true";
import { default as indexgdp3mNqIRLMeta } from "/var/www/mj_client/releases/20250127060019/pages/cl_events/matching_events/index.vue?macro=true";
import { default as cl_eventsMwM3uMKthHMeta } from "/var/www/mj_client/releases/20250127060019/pages/cl_events.vue?macro=true";
import { default as event_bookmarkstas2PvVrcjMeta } from "/var/www/mj_client/releases/20250127060019/pages/event_bookmarks.vue?macro=true";
import { default as indexecN65wWiDlMeta } from "/var/www/mj_client/releases/20250127060019/pages/events/[code]/index.vue?macro=true";
import { default as _91purchase_id_93VsXDYScn1RMeta } from "/var/www/mj_client/releases/20250127060019/pages/events/[code]/purchase_refunds/[purchase_id].vue?macro=true";
import { default as greetingc4ePG6KDUgMeta } from "/var/www/mj_client/releases/20250127060019/pages/events/[code]/purchases/[purchase_id]/greeting.vue?macro=true";
import { default as fixed_purchaseZm8KgpC7WMMeta } from "/var/www/mj_client/releases/20250127060019/pages/events/[code]/tickets/[ticket_id]/amazon_purchases/fixed_purchase.vue?macro=true";
import { default as guest_newInMk87weZuMeta } from "/var/www/mj_client/releases/20250127060019/pages/events/[code]/tickets/[ticket_id]/amazon_purchases/guest_new.vue?macro=true";
import { default as newlr8Eklr0X4Meta } from "/var/www/mj_client/releases/20250127060019/pages/events/[code]/tickets/[ticket_id]/amazon_purchases/new.vue?macro=true";
import { default as fix_purchase2nInwfgG5nMeta } from "/var/www/mj_client/releases/20250127060019/pages/events/[code]/tickets/[ticket_id]/fix_purchase.vue?macro=true";
import { default as newYEEibUCyGjMeta } from "/var/www/mj_client/releases/20250127060019/pages/events/[code]/tickets/[ticket_id]/guest_purchases/new.vue?macro=true";
import { default as purchase_options4es9AFqSPbMeta } from "/var/www/mj_client/releases/20250127060019/pages/events/[code]/tickets/[ticket_id]/purchase_options.vue?macro=true";
import { default as newECKeNnnOA4Meta } from "/var/www/mj_client/releases/20250127060019/pages/events/[code]/tickets/[ticket_id]/purchases/new.vue?macro=true";
import { default as featuresSLzoA8LfixMeta } from "/var/www/mj_client/releases/20250127060019/pages/features.vue?macro=true";
import { default as _91code_93RBpKykANg5Meta } from "/var/www/mj_client/releases/20250127060019/pages/form/[code].vue?macro=true";
import { default as indexHyCYaR6SEcMeta } from "/var/www/mj_client/releases/20250127060019/pages/g_questionnaires/index.vue?macro=true";
import { default as indexas3Myx5PefMeta } from "/var/www/mj_client/releases/20250127060019/pages/index.vue?macro=true";
import { default as _91id_93IM6RRuUZ4TMeta } from "/var/www/mj_client/releases/20250127060019/pages/information/[id].vue?macro=true";
import { default as indexgcfhkAugS8Meta } from "/var/www/mj_client/releases/20250127060019/pages/information/index.vue?macro=true";
import { default as _91id_93sntCVgMaCRMeta } from "/var/www/mj_client/releases/20250127060019/pages/market_insights/[id].vue?macro=true";
import { default as indexxJ4zXI4UYIMeta } from "/var/www/mj_client/releases/20250127060019/pages/market_insights/index.vue?macro=true";
import { default as indexSDGsbJdv53Meta } from "/var/www/mj_client/releases/20250127060019/pages/marriages/index.vue?macro=true";
import { default as mediumUVignccMs6Meta } from "/var/www/mj_client/releases/20250127060019/pages/medium.vue?macro=true";
import { default as indexA8qSKiPwmWMeta } from "/var/www/mj_client/releases/20250127060019/pages/messages/[id]/index.vue?macro=true";
import { default as index0BLuVm2AwlMeta } from "/var/www/mj_client/releases/20250127060019/pages/messages/index.vue?macro=true";
import { default as newsletterRlFUp2ymjaMeta } from "/var/www/mj_client/releases/20250127060019/pages/newsletter.vue?macro=true";
import { default as cl_usersdksBJasSFbMeta } from "/var/www/mj_client/releases/20250127060019/pages/participants/cl_users.vue?macro=true";
import { default as profile6TsCY4bJcPMeta } from "/var/www/mj_client/releases/20250127060019/pages/participants/profile.vue?macro=true";
import { default as successjkzuBZGRGbMeta } from "/var/www/mj_client/releases/20250127060019/pages/payments/[id]/[method]/success.vue?macro=true";
import { default as cancelJJUJ0XDDuoMeta } from "/var/www/mj_client/releases/20250127060019/pages/payments/[id]/cancel.vue?macro=true";
import { default as capture_retrydWxBcRaUBaMeta } from "/var/www/mj_client/releases/20250127060019/pages/payments/[id]/capture_retry.vue?macro=true";
import { default as captureEdXlswoOAIMeta } from "/var/www/mj_client/releases/20250127060019/pages/payments/[id]/capture.vue?macro=true";
import { default as erroryTlDa0XeO3Meta } from "/var/www/mj_client/releases/20250127060019/pages/payments/[id]/error.vue?macro=true";
import { default as retryUkHbbrsKq8Meta } from "/var/www/mj_client/releases/20250127060019/pages/payments/[id]/retry.vue?macro=true";
import { default as _91prefecture_93aniLvVsTgqMeta } from "/var/www/mj_client/releases/20250127060019/pages/prefecture_rankings/[prefecture].vue?macro=true";
import { default as edito8ivcphMdiMeta } from "/var/www/mj_client/releases/20250127060019/pages/profile/edit.vue?macro=true";
import { default as newXXYlKHtFmnMeta } from "/var/www/mj_client/releases/20250127060019/pages/purchases/[id]/companions/new.vue?macro=true";
import { default as indexdWeykNtwuXMeta } from "/var/www/mj_client/releases/20250127060019/pages/purchases/[id]/index.vue?macro=true";
import { default as error1KYRkwgqMIMeta } from "/var/www/mj_client/releases/20250127060019/pages/purchases/error.vue?macro=true";
import { default as indexGaOKRZjed1Meta } from "/var/www/mj_client/releases/20250127060019/pages/purchases/index.vue?macro=true";
import { default as push_notifications9A9JOzcD5Meta } from "/var/www/mj_client/releases/20250127060019/pages/push_notification.vue?macro=true";
import { default as _91id_93uiwxoOrLHCMeta } from "/var/www/mj_client/releases/20250127060019/pages/questionnaires/[id].vue?macro=true";
import { default as thanksRrdynOzEiaMeta } from "/var/www/mj_client/releases/20250127060019/pages/questionnaires/thanks.vue?macro=true";
import { default as _91region_93qWkb4E5h6vMeta } from "/var/www/mj_client/releases/20250127060019/pages/rankings/[region].vue?macro=true";
import { default as indexcPJzwGqLEAMeta } from "/var/www/mj_client/releases/20250127060019/pages/restaurant_inquiry/index.vue?macro=true";
import { default as thanked_inquiry52tK3fmFJzMeta } from "/var/www/mj_client/releases/20250127060019/pages/restaurant_inquiry/thanked_inquiry.vue?macro=true";
import { default as _91organizer_935hhF7zkG9hMeta } from "/var/www/mj_client/releases/20250127060019/pages/review/[organizer].vue?macro=true";
import { default as indexEJAMN6m7uYMeta } from "/var/www/mj_client/releases/20250127060019/pages/review/index.vue?macro=true";
import { default as searchAI2rqZ9DipMeta } from "/var/www/mj_client/releases/20250127060019/pages/search.vue?macro=true";
import { default as _91id_93YdpDM7G2xMMeta } from "/var/www/mj_client/releases/20250127060019/pages/subscriptions/[id].vue?macro=true";
import { default as cancellation_completeOptLfNoDZfMeta } from "/var/www/mj_client/releases/20250127060019/pages/subscriptions/auto_renew/_subscription_id/cancellation_complete.vue?macro=true";
import { default as indexSzpBWsQRNBMeta } from "/var/www/mj_client/releases/20250127060019/pages/subscriptions/auto_renew/_subscription_id/index.vue?macro=true";
import { default as _91id_93MWtX94MF7RMeta } from "/var/www/mj_client/releases/20250127060019/pages/surveys/[id].vue?macro=true";
import { default as features7s5WW7g48kMeta } from "@/pages/features.vue?macro=true";
import { default as indexWgq7Yhe9yyMeta } from "@/pages/purchases/index.vue?macro=true";
import { default as indexXNzOOhDAEHMeta } from "@/pages/purchases/[id]/index.vue?macro=true";
import { default as _91prefecture_9337bnQDk9FnMeta } from "@/pages/prefecture_rankings/[prefecture].vue?macro=true";
import { default as indexrEmrfUgTgfMeta } from "@/pages/market_insights/index.vue?macro=true";
import { default as mediumGyU9QxECDuMeta } from "@/pages/medium.vue?macro=true";
import { default as indexGMapRvxlADMeta } from "@/pages/information/index.vue?macro=true";
import { default as indexzO6lFAtgr4Meta } from "@/pages/g_questionnaires/index.vue?macro=true";
import { default as _91area_930S3BPoldABMeta } from "@/pages/area_rankings/[area].vue?macro=true";
import { default as _91area_group_93apUFkldqThMeta } from "@/pages/area_group_rankings/[area_group].vue?macro=true";
import { default as newsletterbRE2o4Qey5Meta } from "@/pages/newsletter.vue?macro=true";
import { default as event_bookmarksJ9WcWjlEPRMeta } from "@/pages/event_bookmarks.vue?macro=true";
import { default as indexZYpD25cLcNMeta } from "@/pages/events/[code]/index.vue?macro=true";
import { default as thankscs1TqevZmlMeta } from "@/pages/questionnaires/thanks.vue?macro=true";
import { default as search0cZEuubJerMeta } from "@/pages/search.vue?macro=true";
import { default as StaticPageSgcJHrRfpGMeta } from "@/components/StaticPage.vue?macro=true";
import { default as NotFoundPagei7OLvD1CmpMeta } from "@/components/NotFoundPage.vue?macro=true";
export default [
  {
    name: "accounts-cancel_membership_confirm",
    path: "/accounts/cancel_membership_confirm",
    meta: cancel_membership_confirm2L1T6XQxRCMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/accounts/cancel_membership_confirm.vue").then(m => m.default || m)
  },
  {
    name: "amazon_callback",
    path: "/amazon_callback",
    meta: amazon_callbackefEOUYXY38Meta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/amazon_callback.vue").then(m => m.default || m)
  },
  {
    name: "area_group_rankings-area_group",
    path: "/area_group_rankings/:area_group()",
    meta: _91area_group_93gdRVQMasT5Meta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/area_group_rankings/[area_group].vue").then(m => m.default || m)
  },
  {
    name: "area_rankings-area",
    path: "/area_rankings/:area()",
    meta: _91area_93kmBk1gggG9Meta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/area_rankings/[area].vue").then(m => m.default || m)
  },
  {
    name: "bank_accounts-edit",
    path: "/bank_accounts/edit",
    meta: editu3cx1OvjJ6Meta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/bank_accounts/edit.vue").then(m => m.default || m)
  },
  {
    name: "biz_inquiry",
    path: "/biz_inquiry",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/biz_inquiry/index.vue").then(m => m.default || m)
  },
  {
    name: "biz_inquiry-thanked_inquiry",
    path: "/biz_inquiry/thanked_inquiry",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/biz_inquiry/thanked_inquiry.vue").then(m => m.default || m)
  },
  {
    name: "check_in_tickets-receive",
    path: "/check_in_tickets/receive",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/check_in_tickets/receive.vue").then(m => m.default || m)
  },
  {
    name: "cl_events",
    path: "/cl_events",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/cl_events.vue").then(m => m.default || m),
    children: [
  {
    name: "cl_events-dating_events-id",
    path: "dating_events/:id()",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/cl_events/dating_events/[id].vue").then(m => m.default || m)
  },
  {
    name: "cl_events-dating_events",
    path: "dating_events",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/cl_events/dating_events/index.vue").then(m => m.default || m)
  },
  {
    name: "cl_events-matching_events",
    path: "matching_events",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/cl_events/matching_events/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "event_bookmarks",
    path: "/event_bookmarks",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/event_bookmarks.vue").then(m => m.default || m)
  },
  {
    name: "events-code",
    path: "/events/:code()",
    meta: indexecN65wWiDlMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/events/[code]/index.vue").then(m => m.default || m)
  },
  {
    name: "events-code-purchase_refunds-purchase_id",
    path: "/events/:code()/purchase_refunds/:purchase_id()",
    meta: _91purchase_id_93VsXDYScn1RMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/events/[code]/purchase_refunds/[purchase_id].vue").then(m => m.default || m)
  },
  {
    name: "events-code-purchases-purchase_id-greeting",
    path: "/events/:code()/purchases/:purchase_id()/greeting",
    meta: greetingc4ePG6KDUgMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/events/[code]/purchases/[purchase_id]/greeting.vue").then(m => m.default || m)
  },
  {
    name: "events-code-tickets-ticket_id-amazon_purchases-fixed_purchase",
    path: "/events/:code()/tickets/:ticket_id()/amazon_purchases/fixed_purchase",
    meta: fixed_purchaseZm8KgpC7WMMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/events/[code]/tickets/[ticket_id]/amazon_purchases/fixed_purchase.vue").then(m => m.default || m)
  },
  {
    name: "events-code-tickets-ticket_id-amazon_purchases-guest_new",
    path: "/events/:code()/tickets/:ticket_id()/amazon_purchases/guest_new",
    meta: guest_newInMk87weZuMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/events/[code]/tickets/[ticket_id]/amazon_purchases/guest_new.vue").then(m => m.default || m)
  },
  {
    name: "events-code-tickets-ticket_id-amazon_purchases-new",
    path: "/events/:code()/tickets/:ticket_id()/amazon_purchases/new",
    meta: newlr8Eklr0X4Meta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/events/[code]/tickets/[ticket_id]/amazon_purchases/new.vue").then(m => m.default || m)
  },
  {
    name: "events-code-tickets-ticket_id-fix_purchase",
    path: "/events/:code()/tickets/:ticket_id()/fix_purchase",
    meta: fix_purchase2nInwfgG5nMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/events/[code]/tickets/[ticket_id]/fix_purchase.vue").then(m => m.default || m)
  },
  {
    name: "events-code-tickets-ticket_id-guest_purchases-new",
    path: "/events/:code()/tickets/:ticket_id()/guest_purchases/new",
    meta: newYEEibUCyGjMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/events/[code]/tickets/[ticket_id]/guest_purchases/new.vue").then(m => m.default || m)
  },
  {
    name: "events-code-tickets-ticket_id-purchase_options",
    path: "/events/:code()/tickets/:ticket_id()/purchase_options",
    meta: purchase_options4es9AFqSPbMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/events/[code]/tickets/[ticket_id]/purchase_options.vue").then(m => m.default || m)
  },
  {
    name: "events-code-tickets-ticket_id-purchases-new",
    path: "/events/:code()/tickets/:ticket_id()/purchases/new",
    meta: newECKeNnnOA4Meta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/events/[code]/tickets/[ticket_id]/purchases/new.vue").then(m => m.default || m)
  },
  {
    name: "features",
    path: "/features",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "form-code",
    path: "/form/:code()",
    meta: _91code_93RBpKykANg5Meta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/form/[code].vue").then(m => m.default || m)
  },
  {
    name: "g_questionnaires",
    path: "/g_questionnaires",
    meta: indexHyCYaR6SEcMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/g_questionnaires/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "information-id",
    path: "/information/:id()",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/information/[id].vue").then(m => m.default || m)
  },
  {
    name: "information",
    path: "/information",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/information/index.vue").then(m => m.default || m)
  },
  {
    name: "market_insights-id",
    path: "/market_insights/:id()",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/market_insights/[id].vue").then(m => m.default || m)
  },
  {
    name: "market_insights",
    path: "/market_insights",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/market_insights/index.vue").then(m => m.default || m)
  },
  {
    name: "marriages",
    path: "/marriages",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/marriages/index.vue").then(m => m.default || m)
  },
  {
    name: "medium",
    path: "/medium",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/medium.vue").then(m => m.default || m)
  },
  {
    name: "messages-id",
    path: "/messages/:id()",
    meta: indexA8qSKiPwmWMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/messages/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "messages",
    path: "/messages",
    meta: index0BLuVm2AwlMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletter",
    path: "/newsletter",
    meta: newsletterRlFUp2ymjaMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "participants-cl_users",
    path: "/participants/cl_users",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/participants/cl_users.vue").then(m => m.default || m)
  },
  {
    name: "participants-profile",
    path: "/participants/profile",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/participants/profile.vue").then(m => m.default || m)
  },
  {
    name: "payments-id-method-success",
    path: "/payments/:id()/:method()/success",
    meta: successjkzuBZGRGbMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/payments/[id]/[method]/success.vue").then(m => m.default || m)
  },
  {
    name: "payments-id-cancel",
    path: "/payments/:id()/cancel",
    meta: cancelJJUJ0XDDuoMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/payments/[id]/cancel.vue").then(m => m.default || m)
  },
  {
    name: "payments-id-capture_retry",
    path: "/payments/:id()/capture_retry",
    meta: capture_retrydWxBcRaUBaMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/payments/[id]/capture_retry.vue").then(m => m.default || m)
  },
  {
    name: "payments-id-capture",
    path: "/payments/:id()/capture",
    meta: captureEdXlswoOAIMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/payments/[id]/capture.vue").then(m => m.default || m)
  },
  {
    name: "payments-id-error",
    path: "/payments/:id()/error",
    meta: erroryTlDa0XeO3Meta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/payments/[id]/error.vue").then(m => m.default || m)
  },
  {
    name: "payments-id-retry",
    path: "/payments/:id()/retry",
    meta: retryUkHbbrsKq8Meta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/payments/[id]/retry.vue").then(m => m.default || m)
  },
  {
    name: "prefecture_rankings-prefecture",
    path: "/prefecture_rankings/:prefecture()",
    meta: _91prefecture_93aniLvVsTgqMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/prefecture_rankings/[prefecture].vue").then(m => m.default || m)
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    meta: edito8ivcphMdiMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: "purchases-id-companions-new",
    path: "/purchases/:id()/companions/new",
    meta: newXXYlKHtFmnMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/purchases/[id]/companions/new.vue").then(m => m.default || m)
  },
  {
    name: "purchases-id",
    path: "/purchases/:id()",
    meta: indexdWeykNtwuXMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/purchases/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "purchases-error",
    path: "/purchases/error",
    meta: error1KYRkwgqMIMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/purchases/error.vue").then(m => m.default || m)
  },
  {
    name: "purchases",
    path: "/purchases",
    meta: indexGaOKRZjed1Meta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/purchases/index.vue").then(m => m.default || m)
  },
  {
    name: "push_notification",
    path: "/push_notification",
    meta: push_notifications9A9JOzcD5Meta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/push_notification.vue").then(m => m.default || m)
  },
  {
    name: "questionnaires-id",
    path: "/questionnaires/:id()",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/questionnaires/[id].vue").then(m => m.default || m)
  },
  {
    name: "questionnaires-thanks",
    path: "/questionnaires/thanks",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/questionnaires/thanks.vue").then(m => m.default || m)
  },
  {
    name: "rankings-region",
    path: "/rankings/:region()",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/rankings/[region].vue").then(m => m.default || m)
  },
  {
    name: "restaurant_inquiry",
    path: "/restaurant_inquiry",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/restaurant_inquiry/index.vue").then(m => m.default || m)
  },
  {
    name: "restaurant_inquiry-thanked_inquiry",
    path: "/restaurant_inquiry/thanked_inquiry",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/restaurant_inquiry/thanked_inquiry.vue").then(m => m.default || m)
  },
  {
    name: "review-organizer",
    path: "/review/:organizer()",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/review/[organizer].vue").then(m => m.default || m)
  },
  {
    name: "review",
    path: "/review",
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/review/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    meta: searchAI2rqZ9DipMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-id",
    path: "/subscriptions/:id()",
    meta: _91id_93YdpDM7G2xMMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/subscriptions/[id].vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-auto_renew-_subscription_id-cancellation_complete",
    path: "/subscriptions/auto_renew/_subscription_id/cancellation_complete",
    meta: cancellation_completeOptLfNoDZfMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/subscriptions/auto_renew/_subscription_id/cancellation_complete.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-auto_renew-_subscription_id",
    path: "/subscriptions/auto_renew/_subscription_id",
    meta: indexSzpBWsQRNBMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/subscriptions/auto_renew/_subscription_id/index.vue").then(m => m.default || m)
  },
  {
    name: "surveys-id",
    path: "/surveys/:id()",
    meta: _91id_93MWtX94MF7RMeta || {},
    component: () => import("/var/www/mj_client/releases/20250127060019/pages/surveys/[id].vue").then(m => m.default || m)
  },
  {
    path: "/categories/activity/:activity_theme?",
    redirect: {"name":"interests-activity"}
  },
  {
    path: "/categories/interests/:interest?",
    redirect: "/interests"
  },
  {
    path: "/categories/interest",
    redirect: "/interests"
  },
  {
    path: "/ranking_kanto",
    redirect: "/rankings/kanto"
  },
  {
    path: "/ranking_kansai",
    redirect: "/rankings/kansai"
  },
  {
    path: "/ranking_kyushu",
    redirect: "/rankings/kyushu"
  },
  {
    path: "/q/:id?",
    redirect: {"name":"questionnaires-id","query":{"utm_source":"questionnaires","utm_medium":"email","utm_campaign":"questionnaires"}}
  },
  {
    path: "/bank_accounts/new",
    redirect: "/bank_accounts/edit"
  },
  {
    name: "features-page",
    path: "/features/page/:page",
    component: () => import("@/pages/features.vue").then(m => m.default || m)
  },
  {
    name: "purchases-held",
    path: "/purchases/held",
    meta: indexWgq7Yhe9yyMeta || {},
    component: () => import("@/pages/purchases/index.vue").then(m => m.default || m)
  },
  {
    name: "purchases-id",
    path: "/purchases/:id",
    meta: indexXNzOOhDAEHMeta || {},
    component: () => import("@/pages/purchases/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "prefecture-rankings-categories",
    path: "/prefecture_rankings/:prefecture/categories/:category/",
    meta: _91prefecture_9337bnQDk9FnMeta || {},
    component: () => import("@/pages/prefecture_rankings/[prefecture].vue").then(m => m.default || m)
  },
  {
    name: "market_insights-page",
    path: "/market_insights/page/:page",
    component: () => import("@/pages/market_insights/index.vue").then(m => m.default || m)
  },
  {
    name: "medium-page",
    path: "/medium/page/:page",
    component: () => import("@/pages/medium.vue").then(m => m.default || m)
  },
  {
    name: "information-page",
    path: "/information/page/:page",
    component: () => import("@/pages/information/index.vue").then(m => m.default || m)
  },
  {
    name: "g_questionnaires-page",
    path: "/g_questionnaires/page/:page",
    meta: indexzO6lFAtgr4Meta || {},
    component: () => import("@/pages/g_questionnaires/index.vue").then(m => m.default || m)
  },
  {
    name: "area-rankings-categories",
    path: "/area_rankings/:area/categories/:category/",
    meta: _91area_930S3BPoldABMeta || {},
    component: () => import("@/pages/area_rankings/[area].vue").then(m => m.default || m)
  },
  {
    name: "area-group-rankings-categories",
    path: "/area_group_rankings/:area_group/categories/:category/",
    meta: _91area_group_93apUFkldqThMeta || {},
    component: () => import("@/pages/area_group_rankings/[area_group].vue").then(m => m.default || m)
  },
  {
    name: "newsletter-edit",
    path: "/newsletter/edit/",
    meta: newsletterbRE2o4Qey5Meta || {},
    component: () => import("@/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "event-bookmarks-guest",
    path: "/event_bookmarks/guest/",
    component: () => import("@/pages/event_bookmarks.vue").then(m => m.default || m)
  },
  {
    name: "event_previews",
    path: "/event_previews/:code",
    meta: indexZYpD25cLcNMeta || {},
    component: () => import("@/pages/events/[code]/index.vue").then(m => m.default || m)
  },
  {
    name: "questionnaires_expired",
    path: "/questionnaires/expired/",
    component: () => import("@/pages/questionnaires/thanks.vue").then(m => m.default || m)
  },
  {
    name: "fixed-events-page",
    path: "/fixed/:prefecture?/page/:page",
    meta: search0cZEuubJerMeta || {},
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "fixed-events",
    path: "/fixed/:prefecture?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search-page",
    path: "/search/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-events-page",
    path: "/areas/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-events",
    path: "/areas",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "custom-search-events-page",
    path: "/csp/:custom_search/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "custom-search-events",
    path: "/csp/:custom_search",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-custom-search-events-page",
    path: "/areas/:prefecture/csp/:custom_search/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-custom-search-events",
    path: "/areas/:prefecture/csp/:custom_search",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-custom-search-events-page",
    path: "/regions/:region/csp/:custom_search/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-custom-search-events",
    path: "/regions/:region/csp/:custom_search",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-area-custom-search-events-page",
    path: "/areas/:prefecture/:area_group/:area/csp/:custom_search/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-area-custom-search-events",
    path: "/areas/:prefecture/:area_group/:area/csp/:custom_search",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "similar-events-page",
    path: "/similar_events/:event_code/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "similar-events",
    path: "/similar_events/:event_code",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "similar-events-week-page",
    path: "/similar_events/:event_code/week/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "similar-events-week",
    path: "/similar_events/:event_code/week",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-custom-search-categories-events-page",
    path: "/areas/:prefecture/:area_group?/:area?/csp/:custom_search/categories/:category/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-custom-search-categories-events",
    path: "/areas/:prefecture/:area_group?/:area?/csp/:custom_search/categories/:category",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-afs-page",
    path: "/areas/:prefecture/demo/afs_archive_sp/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-afs",
    path: "/areas/:prefecture/demo/afs_archive_sp",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-thisweekend",
    path: "/dates/thisweekend",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-nextweekend",
    path: "/dates/nextweekend",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "interests-activity-page",
    path: "/interests/activity/:activity_theme?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "interests-activity",
    path: "/interests/activity/:activity_theme?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "categories-interests-page",
    path: "/categories/:category/interests/:interest?/:sub_interest?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "categories-interests",
    path: "/categories/:category/interests/:interest?/:sub_interest?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "interests-page",
    path: "/interests/:interest?/:sub_interest?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "interests",
    path: "/interests/:interest?/:sub_interest?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "categories-page",
    path: "/categories/:category/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "categories",
    path: "/categories/:category",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "features-events-page",
    path: "/features/:feature_tag/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "features-events",
    path: "/features/:feature_tag",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "promoters-page",
    path: "/promoters/:promoter/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "promoters",
    path: "/promoters/:promoter",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-interests-activity-page",
    path: "/areas/:prefecture/:area_group?/:area?/interests/activity/:activity_theme?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-interests-activity",
    path: "/areas/:prefecture/:area_group?/:area?/interests/activity/:activity_theme?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-categories-interests-page",
    path: "/areas/:prefecture/:area_group?/:area?/categories/:category/interests/:interest?/:sub_interest?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-categories-interests",
    path: "/areas/:prefecture/:area_group?/:area?/categories/:category/interests/:interest?/:sub_interest?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-interests-page",
    path: "/areas/:prefecture/:area_group?/:area?/interests/:interest?/:sub_interest?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-interests",
    path: "/areas/:prefecture/:area_group?/:area?/interests/:interest?/:sub_interest?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-categories-page",
    path: "/areas/:prefecture/:area_group?/:area?/categories/:category/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-categories",
    path: "/areas/:prefecture/:area_group?/:area?/categories/:category",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-features-events-page",
    path: "/areas/:prefecture/:area_group?/:area?/features/:feature_tag/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-features-events",
    path: "/areas/:prefecture/:area_group?/:area?/features/:feature_tag",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-promoters-page",
    path: "/areas/:prefecture/:area_group?/:area?/promoters/:promoter/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-promoters",
    path: "/areas/:prefecture/:area_group?/:area?/promoters/:promoter",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-page",
    path: "/areas/:prefecture/:area_group?/:area?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas-intl",
    path: "/areas/:prefecture/:area_group?/:area?/intl/:lang",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "areas",
    path: "/areas/:prefecture/:area_group?/:area?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-interests-activity-page",
    path: "/regions/:region/interests/activity/:activity_theme?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-interests-activity",
    path: "/regions/:region/interests/activity/:activity_theme?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-categories-interests-page",
    path: "/regions/:region/categories/:category/interests/:interest?/:sub_interest?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-categories-interests",
    path: "/regions/:region/categories/:category/interests/:interest?/:sub_interest?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-interests-page",
    path: "/regions/:region/interests/:interest?/:sub_interest?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-interests",
    path: "/regions/:region/interests/:interest?/:sub_interest?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-categories-page",
    path: "/regions/:region/categories/:category/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-categories",
    path: "/regions/:region/categories/:category",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-features-events-page",
    path: "/regions/:region/features/:feature_tag/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-features-events",
    path: "/regions/:region/features/:feature_tag",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-promoters-page",
    path: "/regions/:region/promoters/:promoter/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-promoters",
    path: "/regions/:region/promoters/:promoter",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions-page",
    path: "/regions/:region/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "regions",
    path: "/regions/:region",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-interests-activity-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/interests/activity/:activity_theme?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-interests-activity",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/interests/activity/:activity_theme?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-categories-interests-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/categories/:category/interests/:interest?/:sub_interest?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-categories-interests",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/categories/:category/interests/:interest?/:sub_interest?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-interests-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/interests/:interest?/:sub_interest?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-interests",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/interests/:interest?/:sub_interest?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-categories-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/categories/:category/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-categories",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/categories/:category",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-features-events-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/features/:feature_tag/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-features-events",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/features/:feature_tag",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-promoters-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/promoters/:promoter/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-promoters",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/promoters/:promoter",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-areas-interests-activity-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/areas/:prefecture/:area_group?/:area?/interests/activity/:activity_theme?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-areas-interests-activity",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/areas/:prefecture/:area_group?/:area?/interests/activity/:activity_theme?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-areas-categories-interests-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/areas/:prefecture/:area_group?/:area?/categories/:category/interests/:interest?/:sub_interest?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-areas-categories-interests",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/areas/:prefecture/:area_group?/:area?/categories/:category/interests/:interest?/:sub_interest?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-areas-interests-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/areas/:prefecture/:area_group?/:area?/interests/:interest?/:sub_interest?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-areas-interests",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/areas/:prefecture/:area_group?/:area?/interests/:interest?/:sub_interest?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-areas-categories-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/areas/:prefecture/:area_group?/:area?/categories/:category/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-areas-categories",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/areas/:prefecture/:area_group?/:area?/categories/:category",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-areas-features-events-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/areas/:prefecture/:area_group?/:area?/features/:feature_tag/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-areas-features-events",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/areas/:prefecture/:area_group?/:area?/features/:feature_tag",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-areas-promoters-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/areas/:prefecture/:area_group?/:area?/promoters/:promoter/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-areas-promoters",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/areas/:prefecture/:area_group?/:area?/promoters/:promoter",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-areas-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/areas/:prefecture/:area_group?/:area?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-areas",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/areas/:prefecture/:area_group?/:area?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-regions-interests-activity-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/regions/:region/interests/activity/:activity_theme?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-regions-interests-activity",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/regions/:region/interests/activity/:activity_theme?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-regions-categories-interests-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/regions/:region/categories/:category/interests/:interest?/:sub_interest?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-regions-categories-interests",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/regions/:region/categories/:category/interests/:interest?/:sub_interest?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-regions-interests-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/regions/:region/interests/:interest?/:sub_interest?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-regions-interests",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/regions/:region/interests/:interest?/:sub_interest?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-regions-categories-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/regions/:region/categories/:category/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-regions-categories",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/regions/:region/categories/:category",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-regions-features-events-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/regions/:region/features/:feature_tag/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-regions-features-events",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/regions/:region/features/:feature_tag",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-regions-promoters-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/regions/:region/promoters/:promoter/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-regions-promoters",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/regions/:region/promoters/:promoter",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-regions-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/regions/:region/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-regions",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/regions/:region",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates-page",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?/page/:page",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "dates",
    path: "/dates/:year(\\d{4})/:month(\\d|1[012]|0[1-9])?/:day([0-3]\\d)?",
    component: () => import("@/pages/search.vue").then(m => m.default || m)
  },
  {
    path: "/will_mail/hokkaido_1",
    redirect: "/areas/hokkaido/ag0101/sapporo-sta/"
  },
  {
    path: "/will_mail/hokkaido_2",
    redirect: "/areas/hokkaido/ag0101/susukino/"
  },
  {
    path: "/will_mail/hokkaido_3",
    redirect: "/areas/hokkaido/ag0101/sapporocity-other/"
  },
  {
    path: "/will_mail/hokkaido_4",
    redirect: "/areas/hokkaido/ag0102/asahikawa/"
  },
  {
    path: "/will_mail/hokkaido_5",
    redirect: "/areas/hokkaido/ag0103/hakodate/"
  },
  {
    path: "/will_mail/hokkaido_6",
    redirect: "/areas/hokkaido/"
  },
  {
    path: "/will_mail/aomori_1",
    redirect: "/areas/aomori/"
  },
  {
    path: "/will_mail/aomori_2",
    redirect: "/areas/aomori/ag0200/hachinohe/"
  },
  {
    path: "/will_mail/aomori_3",
    redirect: "/areas/aomori/ag0200/hirosaki/"
  },
  {
    path: "/will_mail/aomori_4",
    redirect: "/areas/aomori/ag0200/aomori-other/"
  },
  {
    path: "/will_mail/aomori_5",
    redirect: "/areas/hokkaido/"
  },
  {
    path: "/will_mail/aomori_6",
    redirect: "/areas/iwate/"
  },
  {
    path: "/will_mail/iwate_1",
    redirect: "/areas/iwate/"
  },
  {
    path: "/will_mail/iwate_2",
    redirect: "/areas/iwate/ag0300/morioka/"
  },
  {
    path: "/will_mail/iwate_3",
    redirect: "/areas/iwate/ag0300/ichinoseki/"
  },
  {
    path: "/will_mail/iwate_4",
    redirect: "/areas/iwate/ag0300/iwate-other/"
  },
  {
    path: "/will_mail/iwate_5",
    redirect: "/areas/hokkaido/"
  },
  {
    path: "/will_mail/iwate_6",
    redirect: "/areas/aomori/"
  },
  {
    path: "/will_mail/miyagi_1",
    redirect: "/areas/miyagi/"
  },
  {
    path: "/will_mail/miyagi_2",
    redirect: "/areas/miyagi/ag0401/sendai/"
  },
  {
    path: "/will_mail/miyagi_3",
    redirect: "/areas/miyagi/ag0499/ishinomaki/"
  },
  {
    path: "/will_mail/miyagi_4",
    redirect: "/areas/miyagi/ag0499/miyagi-other/"
  },
  {
    path: "/will_mail/miyagi_5",
    redirect: "/areas/iwate/"
  },
  {
    path: "/will_mail/miyagi_6",
    redirect: "/areas/akita/"
  },
  {
    path: "/will_mail/akita_1",
    redirect: "/areas/akita/"
  },
  {
    path: "/will_mail/akita_2",
    redirect: "/areas/akita/ag0500/yokote/"
  },
  {
    path: "/will_mail/akita_3",
    redirect: "/areas/akita/ag0500/akita-other/"
  },
  {
    path: "/will_mail/akita_4",
    redirect: "/areas/aomori/"
  },
  {
    path: "/will_mail/akita_5",
    redirect: "/areas/iwate/"
  },
  {
    path: "/will_mail/akita_6",
    redirect: "/areas/miyagi/"
  },
  {
    path: "/will_mail/yamagata_1",
    redirect: "/areas/yamagata/"
  },
  {
    path: "/will_mail/yamagata_2",
    redirect: "/areas/yamagata/ag0600/tsuruoka/"
  },
  {
    path: "/will_mail/yamagata_3",
    redirect: "/areas/yamagata/ag0600/yamagata-other/"
  },
  {
    path: "/will_mail/yamagata_4",
    redirect: "/areas/akita/"
  },
  {
    path: "/will_mail/yamagata_5",
    redirect: "/areas/iwate/"
  },
  {
    path: "/will_mail/yamagata_6",
    redirect: "/areas/miyagi/"
  },
  {
    path: "/will_mail/fukushima_1",
    redirect: "/areas/fukushima/"
  },
  {
    path: "/will_mail/fukushima_2",
    redirect: "/areas/fukushima/ag0700/koriyama/"
  },
  {
    path: "/will_mail/fukushima_3",
    redirect: "/areas/fukushima/ag0700/iwaki/"
  },
  {
    path: "/will_mail/fukushima_4",
    redirect: "/areas/fukushima/ag0700/fukushima-other/"
  },
  {
    path: "/will_mail/fukushima_5",
    redirect: "/areas/miyagi/"
  },
  {
    path: "/will_mail/fukushima_6",
    redirect: "/areas/yamagata/"
  },
  {
    path: "/will_mail/ibaraki_1",
    redirect: "/areas/ibaraki/"
  },
  {
    path: "/will_mail/ibaraki_2",
    redirect: "/areas/ibaraki/ag0800/mito/"
  },
  {
    path: "/will_mail/ibaraki_3",
    redirect: "/areas/ibaraki/ag0800/tsukuba/"
  },
  {
    path: "/will_mail/ibaraki_4",
    redirect: "/areas/ibaraki/ag0800/ibaraki-other/"
  },
  {
    path: "/will_mail/ibaraki_5",
    redirect: "/areas/saitama/"
  },
  {
    path: "/will_mail/ibaraki_6",
    redirect: "/areas/tokyo/"
  },
  {
    path: "/will_mail/tochigi_1",
    redirect: "/areas/tochigi/"
  },
  {
    path: "/will_mail/tochigi_2",
    redirect: "/areas/tochigi/ag0900/utsunomiya/"
  },
  {
    path: "/will_mail/tochigi_3",
    redirect: "/areas/tochigi/ag0900/oyama/"
  },
  {
    path: "/will_mail/tochigi_4",
    redirect: "/areas/saitama/"
  },
  {
    path: "/will_mail/tochigi_5",
    redirect: "/areas/tokyo/"
  },
  {
    path: "/will_mail/tochigi_6",
    redirect: "/areas/fukushima/"
  },
  {
    path: "/will_mail/gunma_1",
    redirect: "/areas/gunma/"
  },
  {
    path: "/will_mail/gunma_2",
    redirect: "/areas/gunma/ag1000/maebashi/"
  },
  {
    path: "/will_mail/gunma_3",
    redirect: "/areas/gunma/ag1000/takasaki/"
  },
  {
    path: "/will_mail/gunma_4",
    redirect: "/areas/gunma/ag1000/ota/"
  },
  {
    path: "/will_mail/gunma_5",
    redirect: "/areas/saitama/"
  },
  {
    path: "/will_mail/gunma_6",
    redirect: "/areas/tokyo/"
  },
  {
    path: "/will_mail/saitama_1",
    redirect: "/areas/saitama/"
  },
  {
    path: "/will_mail/saitama_2",
    redirect: "/areas/saitama/ag1101/omiya/"
  },
  {
    path: "/will_mail/saitama_3",
    redirect: "/areas/saitama/ag1199/kawagoe/"
  },
  {
    path: "/will_mail/saitama_4",
    redirect: "/areas/saitama/ag1101/urawa/"
  },
  {
    path: "/will_mail/saitama_5",
    redirect: "/areas/saitama/ag1199/saitama-other/"
  },
  {
    path: "/will_mail/saitama_6",
    redirect: "/areas/tokyo/ag1303/ikebukuro/"
  },
  {
    path: "/will_mail/chiba_1",
    redirect: "/areas/chiba/"
  },
  {
    path: "/will_mail/chiba_2",
    redirect: "/areas/chiba/ag1299/funabashi/"
  },
  {
    path: "/will_mail/chiba_3",
    redirect: "/areas/chiba/ag1299/kashiwa/"
  },
  {
    path: "/will_mail/chiba_4",
    redirect: "/areas/chiba/ag1299/narita/"
  },
  {
    path: "/will_mail/chiba_5",
    redirect: "/areas/chiba/ag1201/makuhari/"
  },
  {
    path: "/will_mail/chiba_6",
    redirect: "/areas/chiba/ag1299/chiba-other/"
  },
  {
    path: "/will_mail/tokyo_1",
    redirect: "/areas/tokyo/ag1304/shinjuku/"
  },
  {
    path: "/will_mail/tokyo_2",
    redirect: "/areas/tokyo/ag1303/ikebukuro/"
  },
  {
    path: "/will_mail/tokyo_3",
    redirect: "/areas/tokyo/ag1309/ebisu/"
  },
  {
    path: "/will_mail/tokyo_4",
    redirect: "/areas/tokyo/ag1305/shibuya/"
  },
  {
    path: "/will_mail/tokyo_5",
    redirect: "/areas/tokyo/ag1308/omotesando/"
  },
  {
    path: "/will_mail/tokyo_6",
    redirect: "/areas/tokyo/ag1302/yurakucho/"
  },
  {
    path: "/will_mail/kanagawa_1",
    redirect: "/areas/kanagawa/"
  },
  {
    path: "/will_mail/kanagawa_2",
    redirect: "/areas/kanagawa/ag1401/minatomirai/"
  },
  {
    path: "/will_mail/kanagawa_3",
    redirect: "/areas/kanagawa/ag1401/yokohama-sta/"
  },
  {
    path: "/will_mail/kanagawa_4",
    redirect: "/areas/tokyo/ag1305/shibuya/"
  },
  {
    path: "/will_mail/kanagawa_5",
    redirect: "/areas/tokyo/ag1304/shinjuku/"
  },
  {
    path: "/will_mail/kanagawa_6",
    redirect: "/areas/tokyo/ag1309/ebisu/"
  },
  {
    path: "/will_mail/niigata_1",
    redirect: "/areas/niigata/"
  },
  {
    path: "/will_mail/niigata_2",
    redirect: "/areas/niigata/ag1500/nagaoka/"
  },
  {
    path: "/will_mail/niigata_3",
    redirect: "/areas/niigata/ag1500/niigata-other/"
  },
  {
    path: "/will_mail/niigata_4",
    redirect: "/areas/gunma/"
  },
  {
    path: "/will_mail/niigata_5",
    redirect: "/areas/saitama/"
  },
  {
    path: "/will_mail/niigata_6",
    redirect: "/areas/tokyo/"
  },
  {
    path: "/will_mail/toyama_1",
    redirect: "/areas/toyama/"
  },
  {
    path: "/will_mail/toyama_2",
    redirect: "/areas/toyama/ag1600/takaoka/"
  },
  {
    path: "/will_mail/toyama_3",
    redirect: "/areas/toyama/ag1600/toyama-other/"
  },
  {
    path: "/will_mail/toyama_4",
    redirect: "/areas/nagano/"
  },
  {
    path: "/will_mail/toyama_5",
    redirect: "/areas/gifu/"
  },
  {
    path: "/will_mail/toyama_6",
    redirect: "/areas/ishikawa/"
  },
  {
    path: "/will_mail/ishikawa_1",
    redirect: "/areas/ishikawa/"
  },
  {
    path: "/will_mail/ishikawa_2",
    redirect: "/areas/ishikawa/ag1700/kanazawa/"
  },
  {
    path: "/will_mail/ishikawa_3",
    redirect: "/areas/aichi/ag2399/aichi-other/"
  },
  {
    path: "/will_mail/ishikawa_4",
    redirect: "/areas/fukui/"
  },
  {
    path: "/will_mail/ishikawa_5",
    redirect: "/areas/aichi/"
  },
  {
    path: "/will_mail/ishikawa_6",
    redirect: "/areas/gifu/"
  },
  {
    path: "/will_mail/fukui_1",
    redirect: "/areas/fukui/"
  },
  {
    path: "/will_mail/fukui_2",
    redirect: "/areas/ishikawa/"
  },
  {
    path: "/will_mail/fukui_3",
    redirect: "/areas/gifu/"
  },
  {
    path: "/will_mail/fukui_4",
    redirect: "/areas/shiga/"
  },
  {
    path: "/will_mail/fukui_5",
    redirect: "/areas/osaka/"
  },
  {
    path: "/will_mail/fukui_6",
    redirect: "/areas/aichi/"
  },
  {
    path: "/will_mail/yamanashi_1",
    redirect: "/areas/yamanashi/"
  },
  {
    path: "/will_mail/yamanashi_2",
    redirect: "/areas/yamanashi/ag1900/kofu/"
  },
  {
    path: "/will_mail/yamanashi_3",
    redirect: "/areas/tokyo/ag1315/tachikawa/"
  },
  {
    path: "/will_mail/yamanashi_4",
    redirect: "/areas/tokyo/ag1304/shinjuku/"
  },
  {
    path: "/will_mail/yamanashi_5",
    redirect: "/areas/tokyo/ag1305/shibuya/"
  },
  {
    path: "/will_mail/yamanashi_6",
    redirect: "/areas/tokyo/ag1303/ikebukuro/"
  },
  {
    path: "/will_mail/nagano_1",
    redirect: "/areas/nagano/"
  },
  {
    path: "/will_mail/nagano_2",
    redirect: "/areas/nagano/ag2000/matsumoto/"
  },
  {
    path: "/will_mail/nagano_3",
    redirect: "/areas/nagano/ag2000/ueda/"
  },
  {
    path: "/will_mail/nagano_4",
    redirect: "/areas/nagano/ag2000/nagano-other/"
  },
  {
    path: "/will_mail/nagano_5",
    redirect: "/areas/yamanashi/"
  },
  {
    path: "/will_mail/nagano_6",
    redirect: "/areas/aichi/"
  },
  {
    path: "/will_mail/gifu_1",
    redirect: "/areas/gifu/"
  },
  {
    path: "/will_mail/gifu_2",
    redirect: "/areas/gifu/ag2100/ogaki/"
  },
  {
    path: "/will_mail/gifu_3",
    redirect: "/areas/gifu/ag2100/gifu-other/"
  },
  {
    path: "/will_mail/gifu_4",
    redirect: "/areas/aichi/"
  },
  {
    path: "/will_mail/gifu_5",
    redirect: "/areas/nagano/"
  },
  {
    path: "/will_mail/gifu_6",
    redirect: "/areas/osaka/"
  },
  {
    path: "/will_mail/shizuoka_1",
    redirect: "/areas/shizuoka/"
  },
  {
    path: "/will_mail/shizuoka_2",
    redirect: "/areas/shizuoka/ag2202/hamamatsu/"
  },
  {
    path: "/will_mail/shizuoka_3",
    redirect: "/areas/shizuoka/ag2299/numazu/"
  },
  {
    path: "/will_mail/shizuoka_4",
    redirect: "/areas/shizuoka/ag2299/shizuoka-other/"
  },
  {
    path: "/will_mail/shizuoka_5",
    redirect: "/areas/aichi/ag2301/nagoya-sta/"
  },
  {
    path: "/will_mail/shizuoka_6",
    redirect: "/areas/tokyo/"
  },
  {
    path: "/will_mail/aichi_1",
    redirect: "/areas/aichi/ag2301/nagoya-sta/"
  },
  {
    path: "/will_mail/aichi_2",
    redirect: "/areas/aichi/ag2301/sakae/"
  },
  {
    path: "/will_mail/aichi_3",
    redirect: "/areas/aichi/ag2302/okazaki/"
  },
  {
    path: "/will_mail/aichi_4",
    redirect: "/areas/aichi/ag2302/toyohashi/"
  },
  {
    path: "/will_mail/aichi_5",
    redirect: "/areas/aichi/ag2302/toyota/"
  },
  {
    path: "/will_mail/aichi_6",
    redirect: "/areas/aichi/ag2399/aichi-other/"
  },
  {
    path: "/will_mail/mie_1",
    redirect: "/areas/mie/"
  },
  {
    path: "/will_mail/mie_2",
    redirect: "/areas/mie/ag2400/tsu/"
  },
  {
    path: "/will_mail/mie_3",
    redirect: "/areas/mie/ag2400/yokkaichi/"
  },
  {
    path: "/will_mail/mie_4",
    redirect: "/areas/mie/ag2400/mie-other/"
  },
  {
    path: "/will_mail/mie_5",
    redirect: "/areas/shiga/"
  },
  {
    path: "/will_mail/mie_6",
    redirect: "/areas/osaka/"
  },
  {
    path: "/will_mail/shiga_1",
    redirect: "/areas/shiga/"
  },
  {
    path: "/will_mail/shiga_2",
    redirect: "/areas/shiga/ag2500/kusatsu/"
  },
  {
    path: "/will_mail/shiga_3",
    redirect: "/areas/shiga/ag2500/otsu/"
  },
  {
    path: "/will_mail/shiga_4",
    redirect: "/areas/shiga/ag2500/shiga-other/"
  },
  {
    path: "/will_mail/shiga_5",
    redirect: "/areas/osaka/"
  },
  {
    path: "/will_mail/shiga_6",
    redirect: "/areas/kyoto/"
  },
  {
    path: "/will_mail/kyoto_1",
    redirect: "/areas/kyoto/"
  },
  {
    path: "/will_mail/kyoto_2",
    redirect: "/areas/kyoto/ag2601/kawaramachi/"
  },
  {
    path: "/will_mail/kyoto_3",
    redirect: "/areas/kyoto/ag2601/karasuma/"
  },
  {
    path: "/will_mail/kyoto_4",
    redirect: "/areas/kyoto/ag2699/kyoto-other/"
  },
  {
    path: "/will_mail/kyoto_5",
    redirect: "/areas/osaka/ag2701/umeda/"
  },
  {
    path: "/will_mail/kyoto_6",
    redirect: "/areas/osaka/ag2702/shinsaibashi/"
  },
  {
    path: "/will_mail/osaka_1",
    redirect: "/areas/osaka/ag2701/umeda/"
  },
  {
    path: "/will_mail/osaka_2",
    redirect: "/areas/osaka/ag2702/shinsaibashi/"
  },
  {
    path: "/will_mail/osaka_3",
    redirect: "/areas/osaka/ag2702/namba/"
  },
  {
    path: "/will_mail/osaka_4",
    redirect: "/areas/osaka/ag2701/dojima/"
  },
  {
    path: "/will_mail/osaka_5",
    redirect: "/areas/osaka/ag2702/honmachi/"
  },
  {
    path: "/will_mail/osaka_6",
    redirect: "/areas/osaka/ag2702/tennoji/"
  },
  {
    path: "/will_mail/hyogo_1",
    redirect: "/areas/hyogo/"
  },
  {
    path: "/will_mail/hyogo_2",
    redirect: "/areas/hyogo/ag2801/sannomiya/"
  },
  {
    path: "/will_mail/hyogo_3",
    redirect: "/areas/hyogo/ag2803/himeji/"
  },
  {
    path: "/will_mail/hyogo_4",
    redirect: "/areas/hyogo/ag2899/hyogo-other/"
  },
  {
    path: "/will_mail/hyogo_5",
    redirect: "/areas/kyoto/"
  },
  {
    path: "/will_mail/hyogo_6",
    redirect: "/areas/osaka/"
  },
  {
    path: "/will_mail/nara_1",
    redirect: "/areas/nara/ag2900/kashihara/"
  },
  {
    path: "/will_mail/nara_2",
    redirect: "/areas/nara/ag2900/nara-other/"
  },
  {
    path: "/will_mail/nara_3",
    redirect: "/areas/osaka/ag2701/umeda/"
  },
  {
    path: "/will_mail/nara_4",
    redirect: "/areas/osaka/ag2702/shinsaibashi/"
  },
  {
    path: "/will_mail/nara_5",
    redirect: "/areas/osaka/ag2702/namba/"
  },
  {
    path: "/will_mail/nara_6",
    redirect: "/areas/osaka/ag2701/dojima/"
  },
  {
    path: "/will_mail/wakayama_1",
    redirect: "/areas/wakayama/"
  },
  {
    path: "/will_mail/wakayama_2",
    redirect: "/areas/osaka/ag2701/umeda/"
  },
  {
    path: "/will_mail/wakayama_3",
    redirect: "/areas/osaka/ag2702/shinsaibashi/"
  },
  {
    path: "/will_mail/wakayama_4",
    redirect: "/areas/osaka/ag2702/namba/"
  },
  {
    path: "/will_mail/wakayama_5",
    redirect: "/areas/osaka/ag2701/dojima/"
  },
  {
    path: "/will_mail/wakayama_6",
    redirect: "/areas/osaka/ag2702/honmachi/"
  },
  {
    path: "/will_mail/tottori_1",
    redirect: "/areas/tottori/"
  },
  {
    path: "/will_mail/tottori_2",
    redirect: "/areas/tottori/ag3100/yonago/"
  },
  {
    path: "/will_mail/tottori_3",
    redirect: "/areas/hiroshima/"
  },
  {
    path: "/will_mail/tottori_4",
    redirect: "/areas/hiroshima/ag3401/hacchobori/"
  },
  {
    path: "/will_mail/tottori_5",
    redirect: "/areas/hiroshima/ag3402/fukuyama/"
  },
  {
    path: "/will_mail/tottori_6",
    redirect: "/areas/shimane/"
  },
  {
    path: "/will_mail/shimane_1",
    redirect: "/areas/shimane/"
  },
  {
    path: "/will_mail/shimane_2",
    redirect: "/areas/shimane/ag3200/matsue/"
  },
  {
    path: "/will_mail/shimane_3",
    redirect: "/areas/shimane/ag3200/izumo/"
  },
  {
    path: "/will_mail/shimane_4",
    redirect: "/areas/hiroshima/"
  },
  {
    path: "/will_mail/shimane_5",
    redirect: "/areas/hiroshima/ag3401/hacchobori/"
  },
  {
    path: "/will_mail/shimane_6",
    redirect: "/areas/hiroshima/ag3402/fukuyama/"
  },
  {
    path: "/will_mail/okayama_1",
    redirect: "/areas/okayama/"
  },
  {
    path: "/will_mail/okayama_2",
    redirect: "/areas/okayama/ag3302/kurashiki/"
  },
  {
    path: "/will_mail/okayama_3",
    redirect: "/areas/hiroshima/ag3401/hiroshimacity-other/"
  },
  {
    path: "/will_mail/okayama_4",
    redirect: "/areas/hiroshima/"
  },
  {
    path: "/will_mail/okayama_5",
    redirect: "/areas/hiroshima/ag3401/hacchobori/"
  },
  {
    path: "/will_mail/okayama_6",
    redirect: "/areas/hiroshima/ag3402/fukuyama/"
  },
  {
    path: "/will_mail/hiroshima_1",
    redirect: "/areas/hiroshima/"
  },
  {
    path: "/will_mail/hiroshima_2",
    redirect: "/areas/hiroshima/ag3401/hacchobori/"
  },
  {
    path: "/will_mail/hiroshima_3",
    redirect: "/areas/hiroshima/ag3401/hiroshima-sta/"
  },
  {
    path: "/will_mail/hiroshima_4",
    redirect: "/areas/hiroshima/ag3402/fukuyama/"
  },
  {
    path: "/will_mail/hiroshima_5",
    redirect: "/areas/hiroshima/ag3401/hiroshimacity-other/"
  },
  {
    path: "/will_mail/hiroshima_6",
    redirect: "/areas/okayama/"
  },
  {
    path: "/will_mail/yamaguchi_1",
    redirect: "/areas/yamaguchi/"
  },
  {
    path: "/will_mail/yamaguchi_2",
    redirect: "/areas/yamaguchi/ag3500/shimonoseki/"
  },
  {
    path: "/will_mail/yamaguchi_3",
    redirect: "/areas/yamaguchi/ag3500/yamaguchi-other/"
  },
  {
    path: "/will_mail/yamaguchi_4",
    redirect: "/areas/hiroshima/"
  },
  {
    path: "/will_mail/yamaguchi_5",
    redirect: "/areas/hiroshima/ag3401/hacchobori/"
  },
  {
    path: "/will_mail/yamaguchi_6",
    redirect: "/areas/hiroshima/ag3401/hiroshima-sta/"
  },
  {
    path: "/will_mail/tokushima_1",
    redirect: "/areas/tokushima/"
  },
  {
    path: "/will_mail/tokushima_2",
    redirect: "/areas/hyogo/"
  },
  {
    path: "/will_mail/tokushima_3",
    redirect: "/areas/okayama/"
  },
  {
    path: "/will_mail/tokushima_4",
    redirect: "/areas/kagawa/"
  },
  {
    path: "/will_mail/tokushima_5",
    redirect: "/areas/kochi/"
  },
  {
    path: "/will_mail/tokushima_6",
    redirect: "/areas/hiroshima/"
  },
  {
    path: "/will_mail/kagawa_1",
    redirect: "/areas/kagawa/"
  },
  {
    path: "/will_mail/kagawa_2",
    redirect: "/areas/kagawa/ag3700/takamatsu/"
  },
  {
    path: "/will_mail/kagawa_3",
    redirect: "/areas/kagawa/ag3700/marugame/"
  },
  {
    path: "/will_mail/kagawa_4",
    redirect: "/areas/tokushima/"
  },
  {
    path: "/will_mail/kagawa_5",
    redirect: "/areas/okayama/"
  },
  {
    path: "/will_mail/kagawa_6",
    redirect: "/areas/ehime/"
  },
  {
    path: "/will_mail/ehime_1",
    redirect: "/areas/ehime/"
  },
  {
    path: "/will_mail/ehime_2",
    redirect: "/areas/kagawa/ag3700/takamatsu/"
  },
  {
    path: "/will_mail/ehime_3",
    redirect: "/areas/kagawa/"
  },
  {
    path: "/will_mail/ehime_4",
    redirect: "/areas/kochi/"
  },
  {
    path: "/will_mail/ehime_5",
    redirect: "/areas/tokushima/"
  },
  {
    path: "/will_mail/ehime_6",
    redirect: "/areas/hiroshima/"
  },
  {
    path: "/will_mail/kochi_1",
    redirect: "/areas/kochi/"
  },
  {
    path: "/will_mail/kochi_2",
    redirect: "/areas/tokushima/"
  },
  {
    path: "/will_mail/kochi_3",
    redirect: "/areas/kagawa/"
  },
  {
    path: "/will_mail/kochi_4",
    redirect: "/areas/ehime/"
  },
  {
    path: "/will_mail/kochi_5",
    redirect: "/areas/kagawa/ag3700/takamatsu/"
  },
  {
    path: "/will_mail/kochi_6",
    redirect: "/areas/kagawa/ag3700/marugame/"
  },
  {
    path: "/will_mail/fukuoka_1",
    redirect: "/areas/fukuoka/ag4001/tenjin/"
  },
  {
    path: "/will_mail/fukuoka_2",
    redirect: "/areas/fukuoka/ag4004/kitakyushu/"
  },
  {
    path: "/will_mail/fukuoka_3",
    redirect: "/areas/fukuoka/ag4001/hakata/"
  },
  {
    path: "/will_mail/fukuoka_4",
    redirect: "/areas/fukuoka/ag4004/kokura/"
  },
  {
    path: "/will_mail/fukuoka_5",
    redirect: "/areas/fukuoka/ag4099/fukuoka-other/"
  },
  {
    path: "/will_mail/fukuoka_6",
    redirect: "/areas/kumamoto/"
  },
  {
    path: "/will_mail/saga_1",
    redirect: "/areas/saga/"
  },
  {
    path: "/will_mail/saga_2",
    redirect: "/areas/fukuoka/ag4001/tenjin/"
  },
  {
    path: "/will_mail/saga_3",
    redirect: "/areas/fukuoka/ag4004/kitakyushu/"
  },
  {
    path: "/will_mail/saga_4",
    redirect: "/areas/fukuoka/ag4001/hakata/"
  },
  {
    path: "/will_mail/saga_5",
    redirect: "/areas/kumamoto/"
  },
  {
    path: "/will_mail/saga_6",
    redirect: "/areas/nagasaki/"
  },
  {
    path: "/will_mail/nagasaki_1",
    redirect: "/areas/nagasaki/"
  },
  {
    path: "/will_mail/nagasaki_2",
    redirect: "/areas/nagasaki/ag4200/sasebo/"
  },
  {
    path: "/will_mail/nagasaki_3",
    redirect: "/areas/fukuoka/ag4001/tenjin/"
  },
  {
    path: "/will_mail/nagasaki_4",
    redirect: "/areas/fukuoka/ag4004/kitakyushu/"
  },
  {
    path: "/will_mail/nagasaki_5",
    redirect: "/areas/fukuoka/ag4001/hakata/"
  },
  {
    path: "/will_mail/nagasaki_6",
    redirect: "/areas/saga/"
  },
  {
    path: "/will_mail/kumamoto_1",
    redirect: "/areas/kumamoto/"
  },
  {
    path: "/will_mail/kumamoto_2",
    redirect: "/areas/fukuoka/ag4001/tenjin/"
  },
  {
    path: "/will_mail/kumamoto_3",
    redirect: "/areas/fukuoka/ag4004/kitakyushu/"
  },
  {
    path: "/will_mail/kumamoto_4",
    redirect: "/areas/fukuoka/ag4001/hakata/"
  },
  {
    path: "/will_mail/kumamoto_5",
    redirect: "/areas/oita/"
  },
  {
    path: "/will_mail/kumamoto_6",
    redirect: "/areas/miyazaki/"
  },
  {
    path: "/will_mail/oita_1",
    redirect: "/areas/oita/"
  },
  {
    path: "/will_mail/oita_2",
    redirect: "/areas/oita/ag4400/beppu/"
  },
  {
    path: "/will_mail/oita_3",
    redirect: "/areas/fukuoka/ag4001/tenjin/"
  },
  {
    path: "/will_mail/oita_4",
    redirect: "/areas/fukuoka/ag4004/kitakyushu/"
  },
  {
    path: "/will_mail/oita_5",
    redirect: "/areas/fukuoka/ag4001/hakata/"
  },
  {
    path: "/will_mail/oita_6",
    redirect: "/areas/kumamoto/"
  },
  {
    path: "/will_mail/miyazaki_1",
    redirect: "/areas/miyazaki/"
  },
  {
    path: "/will_mail/miyazaki_2",
    redirect: "/areas/fukuoka/ag4001/tenjin/"
  },
  {
    path: "/will_mail/miyazaki_3",
    redirect: "/areas/fukuoka/ag4004/kitakyushu/"
  },
  {
    path: "/will_mail/miyazaki_4",
    redirect: "/areas/fukuoka/ag4001/hakata/"
  },
  {
    path: "/will_mail/miyazaki_5",
    redirect: "/areas/kagoshima/"
  },
  {
    path: "/will_mail/miyazaki_6",
    redirect: "/areas/kumamoto/"
  },
  {
    path: "/will_mail/kagoshima_1",
    redirect: "/areas/kagoshima/"
  },
  {
    path: "/will_mail/kagoshima_2",
    redirect: "/areas/fukuoka/ag4001/tenjin/"
  },
  {
    path: "/will_mail/kagoshima_3",
    redirect: "/areas/fukuoka/ag4004/kitakyushu/"
  },
  {
    path: "/will_mail/kagoshima_4",
    redirect: "/areas/fukuoka/ag4001/hakata/"
  },
  {
    path: "/will_mail/kagoshima_5",
    redirect: "/areas/miyazaki/"
  },
  {
    path: "/will_mail/kagoshima_6",
    redirect: "/areas/kumamoto/"
  },
  {
    path: "/will_mail/okinawa_1",
    redirect: "/areas/okinawa/"
  },
  {
    path: "/will_mail/okinawa_2",
    redirect: "/areas/okinawa/ag4700/naha/"
  },
  {
    path: "/will_mail/okinawa_3",
    redirect: "/areas/fukuoka/ag4001/tenjin/"
  },
  {
    path: "/will_mail/okinawa_4",
    redirect: "/areas/fukuoka/ag4004/kitakyushu/"
  },
  {
    path: "/will_mail/okinawa_5",
    redirect: "/areas/fukuoka/ag4001/hakata/"
  },
  {
    path: "/will_mail/okinawa_6",
    redirect: "/areas/miyazaki/"
  },
  {
    name: "static_page",
    path: "/:slug",
    component: () => import("@/components/StaticPage.vue").then(m => m.default || m)
  },
  {
    name: "static_page_with_parent",
    path: "/:parent_slug/:slug",
    component: () => import("@/components/StaticPage.vue").then(m => m.default || m)
  },
  {
    name: "static_page-page",
    path: "/:slug/:page",
    component: () => import("@/components/StaticPage.vue").then(m => m.default || m)
  },
  {
    name: "static_page_with_parent-page",
    path: "/:parent_slug/:slug/:page",
    component: () => import("@/components/StaticPage.vue").then(m => m.default || m)
  },
  {
    name: "not-found",
    path: "/:catchAll(.*)",
    component: () => import("@/components/NotFoundPage.vue").then(m => m.default || m)
  }
]